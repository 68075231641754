
let config = {
  "api": "https://zwj.actuedu.com/api",
  "QRCodeApi": "https://mp.weixin.qq.com/cgi-bin/showqrcode",
  "imgDomain": "https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/"
}

// 测试环境
if (process.env.NODE_ENV === 'development') {
  config = {
    "api": "https://t.actuedu.com/api",
    "QRCodeApi": "https://mp.weixin.qq.com/cgi-bin/showqrcode",
    "imgDomain": "https://ac-static-2022.oss-cn-shenzhen.aliyuncs.com/"
  }
}

module.exports = config