<template>
  <div class="container bg">
    <div class="login-box">
      <div>请使用微信扫码登录</div>
      <img class="qrcode" v-if="url" :src="url" />
    </div>
  </div>
</template>

<script>
import api from '@/api'
import config from '@/config'

export default {
  name: 'Index',
  props: {
    msg: String
  },
  data () {
    return {
      url: '',
      ticket: '',
      timer: null
    }
  },

  methods: {
    async getLoginQRCode () {
      try {
        const json = await api.getLoginQRCode()
        console.log(json, '输出结果')
        const ticket = json && json.data
        if (!ticket) {
          return
        }

        this.url = config.QRCodeApi + '?ticket=' + ticket
        this.ticket = ticket
        this.login()
        this.timer = setInterval(() => {
          this.login()
        }, 1000 * 3)
      } catch (e) {
        //
      }
    },

    async login () {
      try {
        const json = await api.login(this.ticket, 'login')
        const token = json && json.data && json.data.token
        if (!token) {
          return
        }

        const user = json && json.data && json.data.user
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('token', token)
        clearInterval(this.timer)
        location.reload()
      } catch (e) {
        //
      }
    }
  },
  created () {
    this.getLoginQRCode()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  /* border: 1px solid green; */
}

.bg {
  position: absolute;
  background-image: url('http://static.actuedu.com/llgrx3idHOuGcTyOpON_dVI6d69p');
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.login-box {
  position: absolute;
  box-shadow: 0 8px 23px 0 rgb(12 17 55 / 8%);
  /* border: 1px solid green; */
  background: #fff;
  top: calc(100vh / 2 - 200px);
  right: 300px;
  height: 400px;
  width: 400px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px;
}

.qrcode {
  width: 300px;
  height: 300px;
  box-sizing: border-box;
}
</style>
