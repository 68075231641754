import { Extension } from "@tiptap/core";
import "@tiptap/extension-text-style";

export default Extension.create({
  name: "color",

  defaultOptions: {
    types: ["textStyle"]
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          color: {
            default: null,
            renderHTML: (attributes) => {
              console.log(attributes, '颜色')
              let colorValue = attributes.color

              // for (let i = 0; i < 20; i++) {
              //   if (typeof colorValue === 'object') {
              //     colorValue = colorValue.color && colorValue.color.trim()
              //   }
              // }


              // console.log(colorValue)

              // if (!colorValue) {
              //   return {};
              // }

              return {
                style: `color: ${colorValue}`
              };
            },
            parseHTML: (element) => {
              console.log(element.style.color); // Why is the initial value converted to rgb?
              return {
                color: element.style.color.replace(/['"]+/g, "")
              };
            }
          },

          fontSize: {
            default: '16px',
            renderHTML: (attributes) => {
              return {
                style: `font-size: ${attributes.fontSize}`
              };
            },
            parseHTML: (element) => {
              console.log(element.style.fontSize); // Why is the initial value converted to rgb?
              return element.style.fontSize.replace(/['"]+/g, "")
            }
          },

          background: {
            default: null,
            renderHTML: (attributes) => {
              return {
                style: `background: ${attributes.background}`
              };
            },
            parseHTML: (element) => {
              console.log(element.style.background); // Why is the initial value converted to rgb?
              return element.style.background.replace(/['"]+/g, "")
            }
          },
        }
      }
    ];
  },

  addCommands() {
    return {
      setColor: (color) => ({ commands, editor }) => {
        return commands.setMark("textStyle", { color, fontSize: editor.getAttributes('textStyle').fontSize });
      },

      unsetColor: () => ({ chain }) => {
        console.log(111)
        return chain()
          .setMark("textStyle", { color: null, fontSize: null })
          .removeEmptyTextStyle()
          .run();
      },

      setFontSize: (fontSize) => ({ commands, editor }) => {
        console.log(1111222222)
        return commands.setMark("textStyle", { fontSize, color: editor.getAttributes('textStyle').color });
      },

      setBackground: (background) => ({ commands, editor }) => {
        return commands.setMark("textStyle", { background, fontSize: editor.getAttributes('textStyle').fontSize, color: editor.getAttributes('textStyle').color });
      }
    };
  }
});
