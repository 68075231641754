<template>
  <div class="home">
    <el-page-header @back="goBack" content="添加课程">
    </el-page-header>

    <div class="edit-container">
      <el-form :model="form">
        <el-form-item label="章节标题" :label-width="formLabelWidth">
          <el-input class="el-input-title" v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>

        <template>
          <el-form-item label="视频" :label-width="formLabelWidth" prop="video">
            <el-upload
              action=""
              :limit="1"
              accept=".mp4"
              :file-list="videoList"
              :http-request="uploadVideo"
              :on-change="handleVideoChange"
              :on-progress="handleVideoProgress"
              :on-remove="handleVideoRemove"
              :on-success="handleVideoSuccess"
              :auto-upload="false">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
            <div v-if="form.video">
              <video width="320" height="240" controls>
                <source :src="imgDomain + form.video" >
              </video>
            </div>
          </el-form-item>
          <el-form-item label="" :label-width="formLabelWidth" prop="video">
            <div v-if="videoLoading">视频上传中，请不要关闭页面...</div>
          </el-form-item>
        </template>

        <el-form-item label="图文" :label-width="formLabelWidth" prop="video">
        <div style="border: 1px solid #ccc;" class="edit-box">
            <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
            />
            <Editor
                style="height: 700px; width: 600px; overflow-y: hidden;"
                v-model="html"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
                @onChange="onChange"
            />
        </div>
        </el-form-item>

      </el-form>
      <div >
        <el-button @click="goBack">取 消</el-button>
        <el-button type="primary" @click="addContent">完成</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import api from '@/api'
import config1 from '@/config'
import { mapState } from 'vuex'
import { imgDomain } from '@/config'
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default {
  name: 'EditContent',
  data () {
    return {
      imgDomain,
      width: window.outerWidth - 350,
      course_info: {},
      showAddContent: false,
      formLabelWidth: '120px',
      form: {
        content_type: 'video',
        video: '',
        title: '',
        chapter_id: 0,
        index: 0
      },
      videoList: [],
      currentSlectedChapter: '',
      videoLoading: false,
      list: [],
      course_id: 0,
      chapter_id: 0,
      content_id: 0,
      j: 0,
      index: 0,
      editor: null,
      html: '',
      toolbarConfig: { },
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default', // or 'simple'
    }
  },

  props: {
    msg: String
  },

  components: { Editor, Toolbar },

  computed: {
    ...mapState([
      'chapter_list'
    ])
  },

  methods: {
    onCreated(editor) {
      const editor1 = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      this.editor = editor1
    },

    onChange (editor) {
      console.log(editor.getHtml())
    },

    goBack () {
      this.$router.back()
    },

    async uploadVideo (file) {
      this.videoLoading = true
      this.form.video = ''
      const json = await api.upload(file)
      const video = json.data
      if (video.indexOf('.mp4') === -1) {
        this.$message({
          type: 'error',
          message: '视频上传失败'
        })
        this.videoLoading = false
        return
      }

      this.videoLoading = false
      this.form.video = video
      this.videoList = []
      this.$message({
        type: 'success',
        message: '视频上传成功'
      })
    },

    handleVideoChange (file, fileList) {
      this.videoList = fileList
      console.log(this.videoList, file)
      this.uploadVideo(file.raw, file.uid)
    },

    handleVideoProgress (event, file, fileList) {
      console.log(event, file, fileList)
    },

    handleVideoSuccess (resp, file, fileList) {
      console.log(resp, file, fileList)
    },

    handleVideoRemove (file, fileList) {
      console.log(file, fileList)
    },

    async addContent () {
      console.log(this.html)
      if (this.videoLoading) {
        this.$message({
          type: 'error',
          message: '视频还未上传完成，请耐心等待'
        })
        return
      }

      //
      const form = this.form
      if (!form.video && !this.html) {
        this.$message({
          type: 'error',
          message: '请先上传视频或者编辑内容'
        })
        return
      }

      if (!form.title) {
        this.$message({
          type: 'error',
          message: '请先填写标题'
        })
        return
      }

      // 修改
      if (this.content_id) {
        this.$store.dispatch('updateContent', {
          form,
          html: this.html,
          content_id: this.content_id,
          that: this
        })
        return
      }

      this.$store.dispatch('addContent', {
        form,
        html: this.html,
        course_id: this.course_id,
        chapter_id: this.chapter_id,
        that: this
      })
    },
  },

  created () {
    const chapter_id = this.$route.query.chapter_id
    const course_id = this.$route.query.course_id
    const content_id = this.$route.query.content_id
    const j = this.$route.query.j
    const index = this.$route.query.index
    if (!chapter_id || !course_id) {
      location.href = '/'
      return
    }
    this.course_id = course_id
    this.chapter_id = chapter_id
    this.content_id = content_id
    this.j = j
    this.index = index
    if (content_id && j) {
      console.log(this.chapter_list[index].content[j])
      this.form = this.chapter_list[index].content[j]
      this.html = this.chapter_list[index].content[j].content
      this.form.index = index
      this.form.j = j
    }
    const editorConfig = { MENU_CONF: {} }
    const imageConfig = {}
    const videoConfig = {}
    const config = {
      fieldName: 'file',
      timeout: 50 * 1000,
      server: `${config1.api}/upload`,
      headers: {
      },
      customInsert: (res, cb) => {
        console.log(res)
        cb(config1.imgDomain + res.data)
      },
      onError: (file, err, res) => {
        this.$notify(err, 'error')
        console.log(res)
      }
    }
    Object.assign(imageConfig, config)
    Object.assign(videoConfig, config)
    imageConfig.maxFileSize = 20 * 1024 * 1024
    videoConfig.maxFileSize = 50 * 1024 * 1024
    imageConfig.meta = {
      fileName: '富文本编辑器',
      appCode: 'system',
      pathName: 'edit/image'
    }
    videoConfig.meta = {
      fileName: '富文本编辑器',
      appCode: 'system',
      pathName: 'edit/video'
    }
    imageConfig.onError = (file, err, res) => {
      console.log(res)
      if (err.message.indexOf('maximum') > -1) this.$notify({ title: '图片超出限制，最大20M', type: 'error' })
    }
    videoConfig.onError = (file, err, res) => {
      console.log(res)
      if (err.message.indexOf('maximum') > -1) this.$notify({ title: '视频超出限制，最大50M', type: 'error' })
    }
    editorConfig.MENU_CONF['uploadImage'] = imageConfig
    editorConfig.MENU_CONF['uploadVideo'] = videoConfig
    this.editorConfig = editorConfig
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.edit-container {
  background: #fff;
  margin: 20px 0;
  padding: 20px 0;
}

.el-input-title {
  width: 400px !important;
}

.edit-box {
  width: 600px;
}
</style>