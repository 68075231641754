<template>
  <div class="home">
    <el-page-header @back="goBack" content="课程详情">
    </el-page-header>
    <el-descriptions title="" :style="{ width: width + 'px' }">
        <el-descriptions-item label="课程名称">{{ course_info.title }}</el-descriptions-item>
        <el-descriptions-item label="类型">{{ course_info.course_type_name }}</el-descriptions-item>
        <el-descriptions-item label="课程难度">{{ course_info.difficulty }}</el-descriptions-item>
        <el-descriptions-item label="时长">{{ course_info.time }}</el-descriptions-item>
        <el-descriptions-item label="章节数">{{ course_info.number }}</el-descriptions-item>
        <el-descriptions-item label="适合年龄">{{ course_info.age }}</el-descriptions-item>
        <!-- <el-descriptions-item label="备注">
          <el-tag size="small">学校</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="联系地址">江苏省苏州市吴中区吴中大道 1188 号</el-descriptions-item> -->

        <el-descriptions-item label="课程封面">
          <el-image 
              style="width: 235px; height: 100px"
              :src="course_info.cover" 
              :preview-src-list="[course_info.cover]">
            </el-image>
        </el-descriptions-item>
    </el-descriptions>


    <div :style="{ width: width + 'px', textAlign: 'left', fontSize: '16px', margin: '15px 0', color: '#303133' }">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="课程介绍" name="first">
          <el-card shadow="never" :style="{ width: width + 'px', textAlign: 'left', fontSize: '16px', margin: '15px 0', color: '#303133' }">
            <div v-html="course_info.info"></div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="目录" name="second">
          <el-card shadow="never" :style="{ width: width + 'px', textAlign: 'left', fontSize: '16px', margin: '15px 0', color: '#303133' }">
            <div class="chapter-head">
              <div></div>
              <el-button type="primary" @click="addChapter">添加章节</el-button>
            </div>

            <div class="chapter-box">
              <template v-if="chapter_list.length">
                <div v-for="(item, i) of chapter_list" :key="item.chapter_id">
                  <div class="chapter-title-box">
                    <div class="chapter-title">{{ item.title }}</div>
                    <el-dropdown trigger="click" @command="handleCommand">
                      <span class="el-dropdown-link">
                        <el-button type="primary">操作</el-button>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item icon="el-icon-plus" :command="'add-' + i + '-' + item.chapter_id">添加子章节</el-dropdown-item>
                        <el-dropdown-item icon="el-icon-edit" :command="'edit-' + i + '-' + item.chapter_id">修改</el-dropdown-item>
                        <el-dropdown-item icon="el-icon-minus" :command="'remove-' + i + '-' + item.chapter_id">删除</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>

                  <div v-for="(ele, j) of item.content" :key="ele.content_id" class="child-chapter-box">
                    <div>{{ ele.title }}</div>
                    <div class="child-chapter-op-box">
                      <el-dropdown trigger="click" @command="handleContentCommand">
                        <span class="el-dropdown-link" style="cursor: pointer;">
                          <i class="el-icon-more"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item icon="el-icon-edit" :command="'edit-' + i + '-' + item.chapter_id + '-' + j + '-' + ele.content_id">修改</el-dropdown-item>
                          <el-dropdown-item icon="el-icon-minus" :command="'remove-' + i + '-' + item.chapter_id + '-' + j + '-' + ele.content_id">删除</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                      <div style="margin-left: 20px;cursor: pointer;" @click="downloadQr(ele)">
                        <i class="el-icon-download"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="!chapter_list.length">
                <el-empty description="还没有章节内容"></el-empty>
              </template>
            </div>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    </div>

  </div>
</template>

<script>
import api from '@/api'
import { mapState } from 'vuex'

export default {
  name: 'CourseInfo',
  data () {
    return {
      activeName: 'second',
      width: window.outerWidth - 350,
      course_info: {},
      showAddContent: false,
      formLabelWidth: '120px',
      form: {
        content_type: 'video',
        video: '',
        title: '',
        chapter_id: 0,
        index: 0
      },
      videoList: [],
      currentSlectedChapter: '',
      videoLoading: false,
      list: [],
      course_id: 0
    }
  },

  computed: {
    ...mapState([
      'chapter_list'
    ])
  },

  props: {
    msg: String
  },

  methods: {
    downloadQr (ele) {
      console.log(ele)
      const fileName = `${ele.course_id}-${ele.content_id}`
      fetch(`https://t.actuedu.com/qr/${fileName}.jpg`)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${ele.title}.jpg`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
  
    handleClick () {},

    goBack () {
      this.$router.back()
    },

    async uploadVideo (file) {
      this.videoLoading = true
      const json = await api.upload(file)
      const video = json.data
      if (video.indexOf('.mp4') === -1) {
        this.$message({
          type: 'error',
          message: '视频上传失败'
        })
        this.videoLoading = false
        return
      }

      this.videoLoading = false
      this.form.video = video
      this.$message({
        type: 'success',
        message: '视频上传成功'
      })
    },

    handleVideoChange (file, fileList) {
      this.videoList = fileList
      console.log(this.videoList, file)
      this.uploadVideo(file.raw, file.uid)
    },

    handleVideoProgress (event, file, fileList) {
      console.log(event, file, fileList)
    },

    handleVideoSuccess (resp, file, fileList) {
      console.log(resp, file, fileList)
    },

    handleVideoRemove (file, fileList) {
      console.log(file, fileList)
    },


    handleCommand (command) {
      const arr = command.split('-')
      const index = arr[1]
      const chapter_id = arr[2]
      if (command.indexOf('add') !== -1) {
        this.showAddContent = true
        console.log(chapter_id, index)
        this.$router.push({
          path: `/content/edit?chapter_id=${chapter_id}&course_id=${this.course_id}&index=${index}`
        })
      }
      if (command.indexOf('remove') !== -1) {
        this.$confirm('此操作将删除该章节以及其包含的所有子章节, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.removeChapter(chapter_id, index)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })      
        })
      }
      if (command.indexOf('edit') !== -1) {
        const title = this.chapter_list[index].title
        this.updateChapter(chapter_id, index, title)
      }
      console.log(command)
    },
    handleContentCommand (command) {
      const arr = command.split('-')
      const i = arr[1]
      const chapter_id = arr[2]
      const j = arr[3]
      const content_id = arr[4]
      if (command.indexOf('remove') !== -1) {
        this.$confirm('此操作将删除该章节, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.removeContent(content_id, chapter_id, i, j)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })      
        })
      }

      if (command.indexOf('edit') !== -1) {
        this.$router.push({
          path: `/content/edit?chapter_id=${chapter_id}&course_id=${this.course_id}&index=${i}&j=${j}&content_id=${content_id}`
        })
      }
      console.log(command)
    },

    async addChapter () {
      this.$prompt('请输入章节标题', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async ({ value }) => {
        if (!value) {
          return
        }
        this.$store.dispatch('addChapter', {
          title: value,
          course_id: this.course_id,
          that: this
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        })
      })
    },

    async updateChapter (chapter_id, index, title) {
      this.$prompt('请编辑新的章节标题', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: title
      }).then(async ({ value }) => {
        if (!value) {
          return
        }

        this.$store.dispatch('updateChapter', {
          chapter_id,
          index,
          title: value,
          course_id: this.course_id,
          that: this
        })

      }).catch((e) => {
        this.$message({
          type: 'error',
          message: e
        })
      })
    },

    async getCourseInfo () {
      const id = this.$route.query.id
      if (!id) {
        this.$notify.error({
          title: '错误',
          message: '课程不存在'
        })
        return
      }

      const json = await api.getCourseInfo(id)
      console.log(json)

      const course_info = json.data

      if (!course_info) {
        return
      }

      this.course_info = course_info
    },

    async getChapterList () {
      const course_id = this.$route.query.id
      if (!course_id) {
        this.$notify.error({
          title: '错误',
          message: '课程不存在'
        })
        return
      }

      this.$store.dispatch('getChapterList', {
        course_id,
        that: this
      })
    },

  async removeContent (content_id, chapter_id, i, j) {
      this.$store.dispatch('removeContent', {
        course_id: this.course_id,
        content_id,
        chapter_id,
        i,
        j,
        that: this
      })
    },

    async removeChapter (chapter_id, i) {
      this.$store.dispatch('removeChapter', {
        index: i,
        chapter_id,
        course_id: this.course_id,
        that: this
      })
    }
  },

  created () {
    const id = this.$route.query.id
    if (!id) {
      this.$notify.error({
        title: '错误',
        message: '课程不存在'
      })
      return
    }

    this.course_id = id
    this.getCourseInfo()
    this.getChapterList()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.el-descriptions__body {
  padding: 20px !important;
}

.chapter-head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 20px;
}

.chapter-box {
  padding: 0;
  box-sizing: border-box;
}

.chapter-title-box {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.05); */
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.chapter-title {
  font-size: 20px;
  font-weight: bold;
}

.child-chapter-box {
  padding: 25px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
}

.el-dialog {
  /* width: 30vw !important; */
  box-sizing: border-box;
}

.el-form {
  text-align: left;
}

.el-input__inner {
  /* width: 100px !important; */
}

#pane-first img {
  width: 100%;
}

.child-chapter-op-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>