import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import TextAlign from '@tiptap/extension-text-align'
import Color from "./color";
// import fontSize from './fontSize'
import Document from "@tiptap/extension-document"
import Paragraph from "@tiptap/extension-paragraph"
import Text from "@tiptap/extension-text"
import TextStyle from "@tiptap/extension-text-style"
import Highlight from '@tiptap/extension-highlight'
import Image from '@tiptap/extension-image'
// import api from '@/api'
// import { imgDomain } from '@/config'

import { Editor } from '@tiptap/vue-2'

function initEditor (content) {
  const editor = new Editor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: '在这里开始输入图文介绍',
        includeChildren: true
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
        alignments: ['left', 'right', 'center'],
        defaultAlignment: 'left',
      }),
      Document,
      Paragraph,
      Text,
      TextStyle,
      Color,
      Highlight.configure({ multicolor: true }),
      Image.configure({
        inline: true,
      })
    ],
    content
  })

  return editor
}

export default initEditor