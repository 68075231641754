<template>
  <div class="container">
    <div :style="{ width: width * 2 + 'px', textAlign: 'left', fontSize: '24px', margin: '15px 0' }">
      <span>商品分类</span>
    </div>
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix" :style="{ width: width * 2 + 'px' }">
        <!-- <span>我的课程</span> -->
        <el-button type="primary" style="float: right;" @click="addGoodsCategory">添加新分类</el-button>
      </div>

      <el-table
        :data="goodsCategory"
        style="width: 100%">
        <!-- <el-table-column
          fixed
          prop="id"
          label="序号"
          :width="width">
        </el-table-column> -->

        <el-table-column
          prop="name"
          label="分类"
          :width="width">
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          :width="width">
          <template slot-scope="scope">
            <!-- <el-button @click="removeCourse(scope.row)" type="text" style="color: red;" size="small">删除</el-button> -->
            <el-button type="text" @click="updateGoodsCategory(scope.row, scope.$index)" size="small">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
// import api from '@/api'
import { imgDomain } from '@/config'
import { mapState } from 'vuex'

export default {
  name: 'MyCourse',
  data () {
    return {
      imgDomain,
      list: [],
      width: (window.outerWidth - 350) / 2,
      user: {}
    }
  },

  props: {
    msg: String
  },

  computed: {
    ...mapState([
      'goodsCategory'
    ])
  },

  methods: {
    async updateGoodsCategory (row, index) {
      console.log(row, '犀利', index)
      const id = row.id
      const name = row.name

      this.$prompt('修改分类名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: name
      }).then(async ({ value }) => {
        if (!value) {
          return
        }

        this.$store.dispatch('updateGoodsCategory', {
          name: value,
          id,
          index,
          that: this
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        })
      })
    },

    async addGoodsCategory () {
      this.$prompt('请输入分类名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async ({ value }) => {
        if (!value) {
          return
        }

        this.$store.dispatch('addGoodsCategory', {
          name: value,
          that: this
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        })
      })
    }
  },

  created () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.cell {
  display: flex;
  border: 1px solid green !important;
}

.mask-layer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  background: #000000;
  filter: alpha(opacity=50);
  -moz-opacity: 0.5;
  opacity: 0.5;
}

.mask-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
  box-sizing: border-box;
}

.add-box, .qr-box {
  position: relative;
  z-index: 9999;
  background: #fff;
  width: 650px;
  /* height: 500px; */
  box-sizing: border-box;
  opacity: 1;
  padding: 20px;
  text-align: left;
}

.add-box-head {
  margin: auto;
  width: 610px;
  display: flex;
  justify-content: space-between;
}

.el-icon-close {
  font-size: 22px;
  cursor: pointer;
}

.el-form {
  margin: 30px 0;
}

.el-input {
  width: 250px;
}

.add-footer {
  width: 610px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #EEE;
  padding-top: 20px;
}

.qr-code-box {
  width: 610px;
  box-sizing: border-box;
  /* border: 1px solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
