<template>
  <div>
  <div v-if="editor">

    <!-- <el-dropdown trigger="click">
      <span class="el-dropdown-link">
        {{ editor.isActive('paragraph') ? '段落' : '' }} <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item icon="el-icon-plus">黄金糕</el-dropdown-item>
        <el-dropdown-item icon="el-icon-circle-plus">狮子头</el-dropdown-item>
        <el-dropdown-item icon="el-icon-circle-plus-outline">螺蛳粉</el-dropdown-item>
        <el-dropdown-item icon="el-icon-check">双皮奶</el-dropdown-item>
        <el-dropdown-item icon="el-icon-circle-check">蚵仔煎</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown> -->


    <!--  对齐方式 -->
    <!-- <el-dropdown trigger="click">
      <span class="el-dropdown-link">
        {{ getTextAlign() }} <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item icon="el-icon-plus">黄金糕</el-dropdown-item>
        <el-dropdown-item icon="el-icon-circle-plus">狮子头</el-dropdown-item>
        <el-dropdown-item icon="el-icon-circle-plus-outline">螺蛳粉</el-dropdown-item>
        <el-dropdown-item icon="el-icon-check">双皮奶</el-dropdown-item>
        <el-dropdown-item icon="el-icon-circle-check">蚵仔煎</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown> -->

    <div class="editor-menu-item">
      字体颜色
      <input type="color" @input="editor.chain().focus().setColor($event.target.value).run()"
      :value="editor.getAttributes('textStyle').color">
    </div>

    <div class="editor-menu-item">
      背景色
      <input type="color" @input="editor.chain().focus().setBackground($event.target.value).run()"
      :value="editor.getAttributes('textStyle').background">
    </div>

    <button>
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
         {{ editor.getAttributes('textStyle').fontSize || '17px' }} <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <div v-for="item of fontSizeArr" :key="item">
          <el-dropdown-item :command="item" :key="item">{{ item }}</el-dropdown-item>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
    </button>


    <div class="img-button">
      <label class="img-label" for="upload-file">图片</label>
      <input id="upload-file" class="filepath img-input" title="" @change="changepic" accept="image/*" type="file"><br>
    </div>

    <button class="editor-menu-item" @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'editor-is-active': editor.isActive({ textAlign: 'left' }) }">
      左对齐
    </button>
    <button class="editor-menu-item" @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'editor-is-active': editor.isActive({ textAlign: 'center' }) }">
      居中
    </button>
    <button class="editor-menu-item" @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'editor-is-active': editor.isActive({ textAlign: 'right' }) }">
      右对齐
    </button>

    <button class="editor-menu-item" @click="editor.chain().focus().setParagraph().run()" :class="{ 'editor-is-active': editor.isActive('paragraph') }">
      段落
    </button>

    <button class="editor-menu-item" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'editor-is-active': editor.isActive('heading', { level: 1 }) }">
      一级标题
    </button>
    <button class="editor-menu-item" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'editor-is-active': editor.isActive('heading', { level: 2 }) }">
      二级标题
    </button>
    <button class="editor-menu-item" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'editor-is-active': editor.isActive('heading', { level: 3 }) }">
      三级标题
    </button>

    <button class="editor-menu-item" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'editor-is-active': editor.isActive('heading', { level: 4 }) }">
      四级标题
    </button>

    <button class="editor-menu-item" @click="editor.chain().focus().toggleBold().run()" :class="{ 'editor-is-active': editor.isActive('bold') }">
      加粗
    </button>
    <button class="editor-menu-item" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'editor-is-active': editor.isActive('italic') }">
      斜体
    </button>
    <button class="editor-menu-item" @click="editor.chain().focus().toggleStrike().run()" :class="{ 'editor-is-active': editor.isActive('strike') }">
      删除线
    </button>


    <button class="editor-menu-item" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'editor-is-active': editor.isActive('bulletList') }">
      无序列表
    </button>
    <button class="editor-menu-item" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'editor-is-active': editor.isActive('orderedList') }">
      有序列表
    </button>
    <!-- <button @click="editor.chain().focus().toggleCode().run()" :class="{ 'editor-is-active': editor.isActive('code') }">
      code
    </button> -->
    <!-- <button @click="editor.chain().focus().unsetAllMarks().run()">
      clear marks
    </button> -->
    <button class="editor-menu-item" @click="editor.chain().focus().clearNodes().run()">
      清除格式
    </button>

    <!-- <button class="editor-menu-item" @click="editor.chain().focus().undo().run()">
      undo
    </button>
    <button class="editor-menu-item" @click="editor.chain().focus().redo().run()">
      redo
    </button> -->
  </div>
  </div>
</template>

<script>

import api from '@/api'
import { imgDomain } from '@/config'


export default {
  components: {
  },

  data() {
    return {
      content1: '',
      imgDomain,
      fontSizeArr: ['14px', '15px', '16px', '17px', '18px', '19px', '20px', '21px', '22px', '23px', '24px', '25px', '26px', '27px', '28px', '29px', '30px']
    }
  },

  props: {
    editor: {
      type: Object,
      required: true
    }
  },

  mounted() {
  },

  beforeUnmount() {
    //
  },

  methods: {
    getTextAlign () {
      let textAlign = '居中'
      textAlign = this.editor.isActive({ textAlign: 'left' }) ? '左对齐': textAlign
      textAlign = this.editor.isActive({ textAlign: 'right' }) ? '右对齐': textAlign
      textAlign = this.editor.isActive({ textAlign: 'center' }) ? '居中': textAlign
      return textAlign
    },

    handleCommand (command) {
      this.editor.chain().focus().setFontSize(command).run()
    },

   async changepic (e) {
      console.log(e.target.files[0])
      // const img = URL.createObjectURL(e.target.files[0])
      const file = e.target.files[0]

      const json = await api.upload(file)
      const path = json.data
      console.log(json)
      if (path) {
        const url = imgDomain + json.data
        this.editor.chain().focus().setImage({ src: url }).run()
      }
    },

    async addImage() {
      const img = window.prompt('URL')
      const file = this.dataURLtoFile(img, 'jpg')

      const json = await api.upload(file)
      const path = json.data
      console.log(json)
      if (path) {
        const url = imgDomain + json.data
        this.editor.chain().focus().setImage({ src: url }).run()
      }
    },

    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime })
    },

    async uploadCover (file) {
      const json = await api.upload(file)
      const cover = json.data
      console.log(json)
      if (cover) {
        this.cover = cover
      }
    },

    addText () {
      // this.editor.chain().focus().setTextAlign('center').run()
      // this.editor
      //   .chain()
      //   .focus()
      //   .command(({ tr }) => {
      //     // manipulate the transaction
      //     tr.insertText('hey, that’s cool!')

      //     return true
      //   })
      //   .run()

      //   this.editor.commands.insertContent('<h1>Example Text</h1>')

        console.log(this.editor.getHTML())
    }
  }
}
</script>

<style scoped>

.my-custom-is-empty-class {
  color: red;
}

.editor-is-active {
  background: #409EFF;
  color: #fff;
  border: 1px solid #409EFF;
}


.img-label {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 5px;
  color: #333;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}

.img-input {
  display: none;
}

#upload-file {
  display: none;
}

.img-button {
  display: inline-flex;
}

.editor-menu-item {
  display: inline-flex;
  margin: 4px;
}

</style>