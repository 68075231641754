<template>
  <div class="container">
    <div :style="{ width: width * 4 + 'px', textAlign: 'left', fontSize: '24px', margin: '15px 0' }">
      <span>商城页面轮播图</span>
    </div>
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix" :style="{ width: width * 4 + 'px' }">
        <!-- <span>我的课程</span> -->
        <el-button style="float: right; margin-left: 15px;">还可设置{{ 5 - swiperList.length }}个</el-button>
        <el-button type="primary" style="float: right;" @click="handleAddSwiper">添加</el-button>
      </div>

      <el-table
        :data="list"
        style="width: 100%">
        <el-table-column
          label="封面"
          class="course-cover"
          style="border: 1px solid green;"
          :width="width">
          <template slot-scope="scope">
            <img :style="{ width: width * 0.7 + 'px' }" v-if="scope.row" :src="imgDomain + scope.row.cover" />
            <!-- <el-button type="text" size="small">编辑</el-button> -->
          </template>
        </el-table-column>

        <el-table-column
          prop="title"
          label="备注"
          :width="width">
        </el-table-column>

        <el-table-column
          fixed="right"
          label="状态"
          :width="width">
          <template slot-scope="scope">
            <!-- <el-button @click="removeCourse(scope.row)" type="text" style="color: red;" size="small">删除</el-button> -->
            <div v-if="scope.row.status === 0">未发布</div>
            <div v-if="scope.row.status === 1" style="color: green;">已发布</div>
          </template>
        </el-table-column>

        <el-table-column
          fixed="right"
          label="操作"
          :width="width">
          <template slot-scope="scope">
            <!-- <el-button @click="removeCourse(scope.row)" type="text" style="color: red;" size="small">删除</el-button> -->
            <el-button type="text" style="color: red;" v-if="scope.row.status === 1" @click="cancelMemoSwiper(scope.row, scope.$index)" size="small">下线</el-button>
            <el-button type="text" v-if="scope.row.status === 0" @click="publishMemoSwiper(scope.row, scope.$index)" size="small">发布</el-button>
            
            <el-button type="text" @click="handleGoToEdit(scope.row, scope.$index)" size="small">修改</el-button>
            <!-- <el-button type="text" @click="openWechatLink(scope.row, scope.$index)" size="small">查看链接</el-button> -->
          </template>
        </el-table-column>
      </el-table>

      <el-dialog title="设置新的轮播图" :visible.sync="dialogFormVisible">
        <el-form>
          <el-form-item label="课程" >
            <el-select v-model="course_id" placeholder="请选择课程">
              <template v-for="item of list">
                <el-option v-if="item.is_swiper === 0" :key="item.id"  :label="item.title" :value="item.id"></el-option>
              </template>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleAddSwiper">设为轮播图</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import api from '@/api'
import { imgDomain } from '@/config'
import { mapState } from 'vuex'

export default {
  name: 'ShopSwiper',
  data () {
    return {
      imgDomain,
      list: [],
      swiperList: [],
      width: (window.outerWidth - 350) / 4,
      user: {},
      dialogFormVisible: false,
      course_id: ''
    }
  },

  props: {
    msg: String
  },

  computed: {
    ...mapState([
      'series'
    ])
  },

  methods: {
    async getMemoSwiperList () {
      try {
        const json = await api.getSwiperList(2)
        console.log(json, '输出结果')
        const list = json.data
        this.list = list
      } catch (e) {
        console.log(e, '错误了')
        //
      }
    },

    async handleAddSwiper () {
      this.$router.push({
        path: `/shop/add_swiper`
      })
    },

    async cancelMemoSwiper (row, index) {
      console.log(row, index)

      const id = row.id
      console.log(id, 11111)
      if (!id) {
        return
      }

      const result = await api.updateSwiperList(row.title, row.cover, row.url, 0, row.id)
      if (result.ret !== 1000) {
        return
      }


      row.status = 0
      this.list.splice(index, 1, row)
    },

    async publishMemoSwiper (row, index) {
      console.log(row, index)

      const id = row.id
      console.log(id, 11111)
      if (!id) {
        return
      }

      const result = await api.updateSwiperList(row.title, row.cover, row.url, 1, row.id)
      if (result.ret !== 1000) {
        return
      }


      row.status = 1
      this.list.splice(index, 1, row)
    },

    handleGoToEdit (row) {
      this.$router.push({
        path: `/shop/add_swiper?id=${row.id}`
      })
    },

    openWechatLink (row, index) {
      console.log(row, index)
      window.open(row.url, '_blank')
    }
  },

  created () {
    this.getMemoSwiperList()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.course-cover {
}
</style>
