import api from '@/api'

const mutations = {
  getUserInfo (state) {
    const userStr = localStorage.getItem('user')
    if (!userStr) {
      return
    }

    const user = JSON.parse(userStr)
    state.user = user
    state.name = user.name
  },

  getAdminList (state, payload) {
    state.admins = payload.admins
  },

  async getChapterList (state, payload) {
    const course_id = payload.course_id
    const json = await api.getChapterList(course_id)
    console.log(json)

    const list = json.data

    if (!list) {
      return
    }

    state.chapter_list = list
  },

  async addChapter (state, payload) {
    const title = payload.title
    const course_id = payload.course_id
    const that = payload.that
    const json = await api.addChapter(title, course_id)
    if (json.ret !== 1000) {
      that.$message({
        type: 'info',
        message: '添加失败'
      })
      return
    }


    state.chapter_list.push({
      id: json.data,
      title: title,
      content: []
    })
  },

  async removeChapter (state, payload) {
    const course_id = payload.course_id
    const chapter_id = payload.chapter_id
    const index = payload.index
    const that = payload.that
    const json = await api.removeChapter(course_id, chapter_id)

    if (json.ret !== 1000) {
      that.$message({
        type: 'error',
        message: '删除失败，请重试'
      })
      return
    }

    state.chapter_list.splice(index, 1)
  },

  async updateChapter (state, payload) {
    const title = payload.title
    const course_id = payload.course_id
    const chapter_id = payload.chapter_id
    const index = payload.index
    const that = payload.that

    const json = await api.updateChapter(title, course_id, chapter_id)
    if (json.ret !== 1000) {
      that.$message({
        type: 'info',
        message: '修改失败'
      })
      return
    }

    console.log(json)
    state.chapter_list[index].title = title
  },

  async addContent (state, payload) {
    const form = payload.form
    const course_id = payload.course_id
    const chapter_id = payload.chapter_id
    const that = payload.that
    const content = payload.html
    // title, video, content, content_type, course_id, chapter_id
    const json = await api.addContent(form.title, form.video, content, form.content_type, course_id, chapter_id)
    if (json.ret !== 1000) {
      that.$message({
        type: 'success',
        message: '添加失败，请重试'
      })
      return
    }

    console.log('添加课程成功了')

    location.href = `/course/detail?id=${course_id}`
    // const content_id = json.data
    // const list = state.chapter_list
    // const item = list[form.index]
    // item.content.push({
    //   content_id,
    //   ...form
    // })

    // that.$router.back()
  },

  async updateContent (state, payload) {
    const form = payload.form
    const content_id = payload.content_id
    const course_id = payload.course_id
    const that = payload.that
    const content = payload.html
    // title, video, content, content_type, content_id
    const json = await api.updateContent(form.title, form.video, content, form.content_type, content_id)
    if (json.ret !== 1000) {
      that.$message({
        type: 'success',
        message: '添加失败，请重试'
      })
      return
    }

    location.href = `/course/detail?id=${course_id}`

    // const list = state.chapter_list
    // const item = list[form.index]
    // item.content.splice(form.j, 1, {
    //   content_id,
    //   ...form
    // })

    // that.$router.back()
  },

  async removeContent (state, payload) {
    const course_id = payload.course_id
    const chapter_id = payload.chapter_id
    const content_id = payload.content_id
    const i = payload.i
    const j = payload.j
    const that = payload.that
    // course_id, content_id, chapter_id
    const json = await api.removeContent(course_id, content_id, chapter_id)

    if (json.ret !== 1000) {
      that.$message({
        type: 'error',
        message: '删除失败，请重试'
      })
      return
    }

    state.chapter_list[i].content.splice(j, 1)
  },

  async getSeries (state) {
    const json = await api.getSeries()

    if (json.ret !== 1000) {
      return
    }

    state.series = json.data || []
  },

  async addSeries (state, payload) {
    const name = payload.name
    const json = await api.addSeries(name)
    if (json.ret !== 1000) {
      return
    }

    state.series = [...state.series, { name, sid: json.data }]
  },

  async updateSeries (state, payload) {
    const name = payload.name
    const sid = payload.sid
    const index = payload.index
    const json = await api.updateSeries(sid, name)
    if (json.ret !== 1000) {
      return
    }

    state.series.splice(index, 1, { sid, name, is_delete: 0 })
  },

  async getGoodsCategory (state) {
    const json = await api.getCategoryList()

    if (json.ret !== 1000) {
      return
    }

    state.goodsCategory = json.data || []
  },

  async addGoodsCategory (state, payload) {
    const name = payload.name
    const json = await api.addCategory(name)
    if (json.ret !== 1000) {
      return
    }

    state.goodsCategory = [...state.goodsCategory, { name, id: json.data }]
  },

  async updateGoodsCategory (state, payload) {
    const name = payload.name
    const id = payload.id
    const index = payload.index
    const json = await api.editCategory(id, name)
    if (json.ret !== 1000) {
      return
    }

    state.goodsCategory.splice(index, 1, { id, name, is_delete: 0 })
  },
}

export default mutations