
const state = {
  token: localStorage.getItem('token'),
  user: {},
  name: '我的账号',
  admins: [],
  chapter_list: [],
  series: [],
  goodsCategory: [],
}

export default state