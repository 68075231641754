<template>
  <div class="container">
    <div :style="{ width: width * 9 + 'px', textAlign: 'left', fontSize: '24px', margin: '15px 0' }">
      <span>商品</span>
    </div>
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix" :style="{ width: width * 9 + 'px' }">
        <!-- <span>我的课程</span> -->
        <el-button type="primary" style="float: right;" @click="goTo('/xshop/add_goods')">新增</el-button>
      </div>

      <el-table
        :data="list"
        style="width: 100%">
        <!-- <el-table-column
          fixed
          prop="id"
          label="序号"
          :width="width">
        </el-table-column> -->
        <el-table-column
          label="封面"
          class="course-info"
          style="border: 1px solid green;"
          :width="width">
          <template slot-scope="scope">
            <img style="width: 80px; height: 80px;" v-if="scope.row" :src="imgDomain + scope.row.cover" />
            <!-- <el-button type="text" size="small">编辑</el-button> -->
          </template>
        </el-table-column>

        <el-table-column
          prop="name"
          label="名称"
          :width="width">
        </el-table-column>
        <el-table-column
          prop="category_name"
          label="分类"
          :width="width">
        </el-table-column>
        <el-table-column
          prop="price"
          label="价格"
          :width="width">
        </el-table-column>
        <el-table-column
          prop="stock"
          label="库存"
          :width="width">
        </el-table-column>
        <el-table-column
          prop="sales"
          label="销量"
          :width="width">
        </el-table-column>
        <el-table-column
          prop="created_at"
          label="时间"
          :width="width">
        </el-table-column>

        <el-table-column
          prop="is_publish"
          label="状态"
          :width="width">
          <template slot-scope="scope">
            <div :style="{ color: scope.row.is_publish === 1 ? 'green' : '' }">{{ scope.row.is_publish === 1 ? '已上架' : '未上架' }}</div>
          </template>
        </el-table-column>

        <el-table-column
          fixed="right"
          label="操作"
          :width="width">
          <template slot-scope="scope">
            <el-button v-if="!scope.row.is_publish" @click="handlePublish(scope.row)" type="text" size="small">上架</el-button>
            <el-button v-if="scope.row.is_publish" @click="handleUnpublish(scope.row)" type="text" size="small">下架</el-button>
            <el-button @click="openEdit(scope.row)" type="text" size="small">修改</el-button>
            <el-button @click="handleDetele(scope.row)" type="text" style="color: red;" size="small">删除</el-button>
            <!-- <el-button type="text" size="small">编辑</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import api from '@/api'
import { imgDomain } from '@/config'
import { format } from '@/common/time'
import { mapState } from 'vuex'

export default {
  name: 'MyCourse',
  data () {
    return {
      imgDomain,
      list: [],
      width: (window.outerWidth - 350) / 9,
      user: {}
    }
  },

  props: {
    msg: String
  },

  computed: {
    ...mapState([
      'goodsCategory'
    ])
  },

  methods: {
    goTo (path) {
      this.$router.push({
        path
      })
    },

    async handlePublish (goods) {
      const json = await api.publishGoods(goods.id)
      if (json.ret !== 1000) {
        alert('请重试试')
        return
      }
  
      console.log(json)
      location.reload()
    },

    async handleUnpublish (goods) {
      const json = await api.unpublishGoods(goods.id)
      if (json.ret !== 1000) {
        alert('请重试试')
        return
      }
  
      console.log(json)
      location.reload()
    },

    async handleDetele (news) {
      alert('此功能暂时不可用')
      // const json = await api.deleteNews(news.news_id)
      // if (json.ret !== 1000) {
      //   alert('请重试试')
      //   return
      // }
  
      // console.log(json)
      // location.reload()
    },

    handleEdit (news) {},

    openDetail (row) {
      console.log(row)
      this.$router.push({
        path: `/course/detail?id=${row.id}`
      })
    },

    openEdit (row) {
      this.$router.push({
        path: `/xshop/add_goods?id=${row.id}`
      })
    },

    goToAdd () {
      this.$router.push({
        path: '/xshop/add_goods'
      })
    },

    async getGoodsList () {
      try {
        const json = await api.getGoodsList()
        console.log(json, '输出结果')
        const data = json.data
        const list = data.list
        list.forEach(item => {
          item.created_at = format(new Date(+item.created_at * 1000), 'yyyy-MM-dd hh:mm')
          this.goodsCategory.forEach(category => {
            if (category.id === item.category_id) {
              // alert(111)
              item.category_name = category.name
            }
          })
        })
        this.list = list
      } catch (e) {
        //
        console.log(e)
      }
    }
  },

  created () {
    this.getGoodsList()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.cell {
  display: flex;
  border: 1px solid green !important;
}

.mask-layer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  background: #000000;
  filter: alpha(opacity=50);
  -moz-opacity: 0.5;
  opacity: 0.5;
}

.mask-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
  box-sizing: border-box;
}

.add-box, .qr-box {
  position: relative;
  z-index: 9999;
  background: #fff;
  width: 650px;
  /* height: 500px; */
  box-sizing: border-box;
  opacity: 1;
  padding: 20px;
  text-align: left;
}

.add-box-head {
  margin: auto;
  width: 610px;
  display: flex;
  justify-content: space-between;
}

.el-icon-close {
  font-size: 22px;
  cursor: pointer;
}

.el-form {
  margin: 30px 0;
}

.el-input {
  width: 250px;
}

.add-footer {
  width: 610px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #EEE;
  padding-top: 20px;
}

.qr-code-box {
  width: 610px;
  box-sizing: border-box;
  /* border: 1px solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
