<template>
  <div class="container">
    <el-page-header @back="goBack" content="添加商品分类">
    </el-page-header>

    <el-card class="box-card" shadow="never">
      <el-form :model="ruleForm" :rules="rules" label-width="80px" class="demo-ruleForm">

        <el-form-item label="名称" prop="name">
          <el-input class="editor-meta-input" v-model="ruleForm.name"></el-input>
        </el-form-item>

        <el-form-item label="备注" prop="tips">
          <el-input class="editor-meta-input" v-model="ruleForm.tips"></el-input>
        </el-form-item>

        <el-form-item label="" style="margin-top: 50px;">
          <div class="footer-box">
            <el-button @click="goBack">取消</el-button>
            <el-button type="primary" @click="submit">完成</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-card>



    <el-dialog title="裁剪图片" class="cropper-dialog" :visible.sync="showMask">
      <div class="cropper-box">
        <vue-cropper
          ref="cropper"
          class="vue-cropper"
          autoCrop
          canMoveBox
          canMove
          fixed
          original
          centerBox
          autoCropWidth
          :fixedNumber="[2.53, 1]"
          :img="coverTemp"
        ></vue-cropper>
        <div class="cropper-submit-box">
          <el-button @click="handleCancelCover">取消</el-button>
          <el-button type="primary" @click="cropper">完成</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import api from '@/api'
import { VueCropper }  from 'vue-cropper'
import api from '@/api'

import { imgDomain } from '@/config'
import { mapState } from 'vuex'

export default {
  name: 'AddMemoSwiper',
  data () {
    return {
      imgDomain,
      list: [],
      width: (window.outerWidth - 350) / 2,
      user: {},
      cover: '',
      coverTemp: '',
      showMask: false,
      ruleForm: {
        name: '',
        tips: ''
      },
      rules: {
        name: [
          { required: true, message: '输入名称', trigger: 'blur' }
        ]
      }
    }
  },

  props: {
    msg: String
  },

  computed: {
    ...mapState([
      'series'
    ])
  },

  components: {
    VueCropper
  },

  methods: {
    goBack () {
      this.$router.back()
    },

    async getMemoSwiper (id) {
      try {
        const json = await api.getCategory(id)
        console.log(json, '输出结果')
        const swiper = json.data
        console.log(swiper)
        this.cover = swiper.cover
        this.ruleForm = swiper
      } catch (e) {
        console.log(e, '错误了')
        //
      }
    },

    async updateSeries (row, index) {
      console.log(row, '犀利', index)
      const sid = row.sid
      const name = row.name

      this.$prompt('修改系列名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: name
      }).then(async ({ value }) => {
        if (!value) {
          return
        }

        this.$store.dispatch('updateSeries', {
          name: value,
          sid,
          index,
          that: this
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        })
      })
    },

    handleUploadImg (e) {
      console.log(e.target.files[0])
      this.coverTemp = URL.createObjectURL(e.target.files[0])
      this.showMask = true
    },

    async submit () {
      console.log(this.ruleForm)
      if (!this.ruleForm.name) {
        this.$message({
          type: 'info',
          message: '输入完整信息'
        })
        return
      }

      // 修改
      if (this.ruleForm.id) {
        const result = await api.editCategory( this.ruleForm.id, this.ruleForm.name, this.ruleForm.tips)
        if (result.ret !== 1000) {
          this.$message({
            type: 'error',
            message: '刷新页面重试'
          })
          return
        }

        location.href = '/shop/category_list'
        return
      }

      // 新增
      const result = await api.addCategory(this.ruleForm.name, this.ruleForm.tips)
      if (result.ret !== 1000) {
        this.$message({
          type: 'error',
          message: '刷新页面重试'
        })
        return
      }

      location.href = '/shop/category_list'
    },

    cropper () {
      this.$refs.cropper.getCropData(data => {
        this.uploadCover(this.dataURLtoFile(data, 'jpg'))
        this.showMask = false
      })
    },

    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime })
    },

    handleCancelCover () {
      if (this.cover.indexOf('-') === -1) {
        // this.cover = '' 
      }

      this.showMask = false
    },

    handleCancel () {
      location.href = '/course/list'
    },

    async uploadCover (file) {
      const json = await api.upload(file)
      const cover = json.data
      console.log(json)
      if (cover) {
        this.cover = cover
      }
    },
  },

  created () {
    const id = this.$route.query.id
    if (!id) {
      this.$router.goBack()
      return
    }

    this.getMemoSwiper(id)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box-card {
  margin-top: 20px;
}

#upload-file {
  display: block;
}


.vue-cropper {
  z-index: 5;
  width: 100%;
  height: 350px;
  margin-bottom: 20px;
  border: 1px solid red;
  box-sizing: border-box;
}

.cropper-box {
  /* width: 300px; */
  height: 350px;
  border: 1px solid green;
  width: 100%;
  box-sizing: border-box;
}

.cropper-dialog {
  display: flex;
  border: 1px solid blue;
  box-sizing: border-box;
}

.cropper-submit-box {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.upload-cover {
  font-size: 16px;
  border-radius: 5px;
  color: #333;
  border: 1px dashed #ccc;
  background-color: #fff;
  cursor: pointer;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: calc(200px / 1.83);
}

.upload-cover-r {
  position: absolute;
  left: 70px;
  top: calc(70px / 1.83);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 16px !important;
  width: 60px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
