<template>
  <div class="add-course-container container" ref="main">
    <div class="course-editor-box">
      <ToolBarVue v-if="editor" class="editor-toolbar" :editor="editor" />
      
      <div class="scoll-box" v-if="scrollStatus !== 1">
        <div @click="toTop" class="scoll-box-item" v-if="scrollStatus === 2">
          <img class="scoll-icon" src="../assets/toBack.png" />
          <div>回到顶部</div>
        </div>
        <div @click="toBottom" class="scoll-box-item" v-if="scrollStatus === 0">
          <img class="scoll-icon" src="../assets/toBottom.png" />
          <div>章节设置</div>
        </div>
      </div>

      <el-input v-model="form.title" class="name" placeholder="在这里输入章节名称"></el-input>

      <editor-content :editor="editor" v-model="courseDesc" />
      <div class="editor-meta-box">
        <div style="font-size: 18px;font-weight: bold; padding-bottom: 10px;">章节设置</div>
        <!-- <div class="editor-meta-upload-img-box">
          <div style="font-size: 14px; color: #606266;">添加视频</div>
          <div class="editor-meta-upload-img" v-if="cover.indexOf('image/') === -1">
            <label class="upload-cover" for="upload-file1">添加章节视频</label>
            <input id="upload-file1" class="filepath img-input" title="" @change="handleUploadImg" accept="image/*" type="file"><br>
          </div>

          <div class="editor-meta-upload-img-r" v-if="cover.indexOf('image/') !== -1">
            <label class="upload-cover-r" for="upload-file2">更换</label>
            <input  id="upload-file2" class="filepath img-input" title="" @change="handleUploadImg" accept="image/*" type="file">
            <img  class="cover-preview" :src="imgDomain + cover" />
          </div>
        </div> -->
        <div>
          <el-form label-width="80px" class="demo-ruleForm">
            <template>
              <el-form-item label="视频" :label-width="formLabelWidth" prop="video">
                <el-upload
                  action=""
                  :limit="1"
                  accept=".mp4"
                  :file-list="videoList"
                  :http-request="uploadVideo"
                  :on-change="handleVideoChange"
                  :on-progress="handleVideoProgress"
                  :on-remove="handleVideoRemove"
                  :on-success="handleVideoSuccess"
                  :auto-upload="false">
                  <el-button type="primary">点击上传</el-button>
                  <el-button @click.stop="handleClickVideo">删除视频</el-button>
                </el-upload>
                <div v-if="form.video">
                  <video width="320" height="240" controls>
                    <source :src="imgDomain + form.video" >
                  </video>
                </div>
              </el-form-item>
              <el-form-item label="" :label-width="formLabelWidth" prop="video">
                <div v-if="videoLoading">视频上传中，请不要关闭页面...</div>
              </el-form-item>
            </template>
          </el-form>
        </div>
      </div>

      <div class="editor-footer-box">
          <el-popconfirm
            :title="this.content_id ? '将丢失本次修改，确定取消？' : '取消将丢失正在编辑的内容，确定取消？'"
            @confirm="handleCancel"
            style="margin-right: 20px"
          >
            <el-button slot="reference">取消</el-button>
          </el-popconfirm>
        <!-- <el-button >取消</el-button> -->
        <el-button type="primary" @click="submit">完成</el-button>
      </div>
    </div>
  </div>
</template>

<script>
// import api from '@/api'
// import config1 from '@/config'
import '@wangeditor/editor/dist/css/style.css'
// import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { imgDomain } from '@/config'
import { mapState } from 'vuex'
import api from '@/api'
import { putFile } from '@/common/upload'
import { EditorContent } from '@tiptap/vue-2'
console.log(putFile)
import ToolBarVue from '@/common/ToolBar.vue';
import initEditor from '@/common/editor';
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'AdminList',
  data () {
    return {
      scrollStatus: 0,
      editor: null,
      imgDomain,
      admins: [],
      width: window.outerWidth - 350,
      name: '',
      courseDesc: '',
      cover: '',
      coverTemp: '',
      showMask: false,
      formLabelWidth: '120px',
      form: {
        content_type: 'video',
        video: '',
        title: '',
        chapter_id: 0,
        index: 0
      },
      videoList: [],
      currentSlectedChapter: '',
      videoLoading: false,
      list: [],
      course_id: 0,
      chapter_id: 0,
      content_id: 0,
    }
  },


  props: {
    msg: String
  },

  mounted() {
    // this.editor.options.extensions[1].options.placeholder = "My own placeholder :D"
    // this.editor.chain().focus().setFontSize('16px').run()
  },

  beforeUnmount() {
    this.editor.destroy()
    //
  },

  computed: {
    ...mapState([
      'series'
    ])
  },

  components: {
    EditorContent,
    ToolBarVue
  },

  methods: {
    handleClickVideo () {
      this.form.video = ''
    },

   async uploadVideo (file) {
     const videoR = this.form.video
     try {
      this.videoLoading = true
      const video = `image/${uuidv4()}.mp4`
      this.form.video = ''
      const result = await putFile(video, file)
      this.videoLoading = false

      const status = result.res.status
      if (status !== 200) {
        if (videoR) {
          this.form.video = videoR
        }
        return
      }

      this.form.video = video
      this.videoList = []
      this.$message({
        type: 'success',
        message: '视频上传成功'
      })
      console.log(result, video, file, uuidv4())
     } catch (e) {
       console.log(e)
        this.$message({
          type: 'error',
          message: '视频上传失败'
        })
        if (videoR) {
          this.form.video = videoR
        }
        this.videoLoading = false
     }
      // this.videoLoading = true
      // this.form.video = ''
      // const json = await api.upload(file)
      // const video = json.data
      // if (video.indexOf('.mp4') === -1) {
      //   this.$message({
      //     type: 'error',
      //     message: '视频上传失败'
      //   })
      //   this.videoLoading = false
      //   return
      // }

      // this.videoLoading = false
      // this.form.video = video
      // this.videoList = []
      // this.$message({
      //   type: 'success',
      //   message: '视频上传成功'
      // })
    },

    handleVideoChange (file, fileList) {
      this.videoList = fileList
      console.log(this.videoList, file)
      this.uploadVideo(file.raw, file.uid)
    },

    handleVideoProgress (event, file, fileList) {
      console.log(event, file, fileList)
    },

    handleVideoSuccess (resp, file, fileList) {
      console.log(resp, file, fileList)
    },

    handleVideoRemove (file, fileList) {
      console.log(file, fileList)
    },

    toTop () {
      //参数i表示间隔的幅度大小，以此来控制速度
      let c = null
      const i = 50
      document.documentElement.scrollTop -= i
      if (document.documentElement.scrollTop > 0) {
        c = setTimeout(()=> this.toTop(i), 16)
      } else {
        clearTimeout(c)
      }
    },

    toBottom () {
      const i = 50
      const clientHeight = document.documentElement.clientHeight||document.body.clientHeight
      const scrollHeight = document.documentElement.scrollHeight
      const height = scrollHeight - clientHeight //超出窗口上界的值就是底部的scrolTop的值
      let c = null
      document.documentElement.scrollTop += i
      if (document.documentElement.scrollTop < height) {
        c= setTimeout(()=> this.toBottom(i), 16)
      } else {
        clearTimeout(c)
      }
    },

    handleUploadImg (e) {
      console.log(e.target.files[0])
      this.coverTemp = URL.createObjectURL(e.target.files[0])
      this.showMask = true
    },

    handleCancelCover () {
      if (this.cover.indexOf('-') === -1) {
        // this.cover = '' 
      }

      this.showMask = false
    },

    handleCancel () {
      location.href = `/course/detail?id=${this.course_id}`
    },

    handleNext () {
      console.log(this.courseDesc, this.name, this.editor.getHTML())
    },

    async changepic (e) {
      console.log(e.target.files[0])
      // const img = URL.createObjectURL(e.target.files[0])
      const file = e.target.files[0]

      const json = await api.upload(file)
      const path = json.data
      console.log(json)
      if (path) {
        const url = imgDomain + json.data
        this.editor.chain().focus().setImage({ src: url }).run()
      }
    },

    async addImage() {
      const img = window.prompt('URL')
      const file = this.dataURLtoFile(img, 'jpg')

      const json = await api.upload(file)
      const path = json.data
      console.log(json)
      if (path) {
        const url = imgDomain + json.data
        this.editor.chain().focus().setImage({ src: url }).run()
      }
    },

    cropper () {
      this.$refs.cropper.getCropData(data => {
        this.uploadCover(this.dataURLtoFile(data, 'jpg'))
        this.showMask = false
      })
    },

    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime })
    },

    async uploadCover (file) {
      const json = await api.upload(file)
      const cover = json.data
      console.log(json)
      if (cover) {
        this.cover = cover
      }
    },

    async getContent () {
      const id = this.content_id
      const json = await api.getContent(id)
      console.log(json, '结果是啊')

      const content_info = json.data

      if (!content_info) {
        this.handleCancel()
        return
      }

      this.form = content_info
      this.editor = initEditor(content_info.content)
    },


    async submit () {
      const html = this.editor.getHTML()
      console.log(1111, this.form, html)
      if (this.videoLoading) {
        this.$message({
          type: 'error',
          message: '视频还未上传完成，请耐心等待'
        })
        return
      }

      //
      const form = this.form
      if (!form.video && !html) {
        this.$message({
          type: 'error',
          message: '请先上传视频或者编辑内容'
        })
        return
      }

      if (!form.title) {
        this.$message({
          type: 'error',
          message: '请先填写标题'
        })
        return
      }

      // 修改
      if (this.content_id) {
        this.$store.dispatch('updateContent', {
          form,
          html,
          content_id: this.content_id,
          course_id: this.course_id,
          that: this
        })
        return
      }

      this.$store.dispatch('addContent', {
        form,
        html,
        course_id: this.course_id,
        chapter_id: this.chapter_id,
        that: this
      })
    }
  },

  created () {
    const height = document.documentElement.scrollHeight
    window.onscroll = () => {
      if (document.documentElement.scrollTop > height / 2) {
        this.scrollStatus = 2
      } else if (document.documentElement.scrollTop < height / 2) {
        this.scrollStatus = 0
      } else {
        this.scrollStatus = 1
      }
    }

    //  修改章节
    const course_id = this.$route.query.course_id
    const chapter_id = this.$route.query.chapter_id
    const content_id = this.$route.query.content_id
    const j = this.$route.query.j
    const index = this.$route.query.index

    this.course_id = course_id
    this.chapter_id = chapter_id
    this.content_id = content_id
    this.j = j
    this.index = index
    // alert(this.course_id)
    if (content_id) {
      this.getContent()
    } else {
      this.editor = initEditor('')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.add-course-container {
  background: #f8f8f8;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  box-sizing: border-box;
}

.course-editor-box {
  background: #fff;
  width: 760px;
  box-sizing: border-box;
  /* border: 1px solid red; */
  position: relative;
  padding-top: 100px;
  padding-bottom: 120px;
}

.name .el-input__inner {
  border: none;
}

.name .el-input__inner::placeholder {
  font-size: 20px;
  color: #adb5bd;
}

.name {
  padding: 0px 16px;
  /* border: 1px solid green; */
  box-sizing: border-box;
}




/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

/* Basic editor styles */
.ProseMirror>*+* {
  margin-top: 0.75em;
}

.ProseMirror {
  padding: 0px 30px;
  outline: none;
  min-height: 90vh;

  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 17px;
}

.ProseMirror:hover {
  outline: none;
}

.ProseMirror ul,
.ProseMirror ol {
  padding: 0 1rem;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}

.ProseMirror code {
  background-color: rgba(97, 97, 97, 0.1);
  color: #616161;
}

.ProseMirror pre {
  background: #0D0D0D;
  color: #FFF;
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.ProseMirror img {
  max-width: 100%;
  height: auto;
}

.ProseMirror-selectednode {
  outline: 3px solid #68CEF8;
}

.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}

.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
}

.my-custom-is-empty-class {
  color: red;
}

.editor-is-active {
  background: #409EFF;
  color: #fff;
  border: 1px solid #409EFF;
}


  .img-label {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 5px;
    color: #333;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
  }

  input {
      display: block;
    }
  #upload-file {
    display: none;
  }


.editor-toolbar {
  position: fixed;
  width: 760px;
  box-sizing: border-box;
  background: #f8f8f8;
  top: 0;
  z-index: 4;
  padding: 10px;
}

.editor-footer-box {
  width: 760px;
  position: fixed;
  box-sizing: border-box;
  z-index: 4;
  bottom: 0;
  border-top: 1px solid rgba(13, 13, 13, 0.1);
  padding: 20px;
  display: flex;
  justify-content: center;
  background: #fff;
}

.editor-meta-box {
  border-top: 1px solid rgba(13, 13, 13, 0.1);
  width: 700px;
  margin: auto;
  padding: 20px 0;
}

.editor-meta-upload-img-box {
  display: flex;
  padding: 10px 0;
}

.editor-meta-upload-img-r {
  margin-left: 20px;
  position: relative;
}

/* .editor-meta-upload-img-btn {
  border: 1px solid green;
  cursor: pointer;
  width: 375px;
  height: calc(375px / 1.83);
} */

.upload-cover {
  font-size: 16px;
  border-radius: 5px;
  color: #333;
  border: 1px dashed #ccc;
  background-color: #fff;
  cursor: pointer;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: calc(200px / 1.83);
}

.upload-cover-r {
  position: absolute;
  left: 70px;
  top: calc(70px / 1.83);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 16px !important;
  width: 60px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-input {
  display: none
}

#upload-file {
  display: block;
}


.vue-cropper {
  z-index: 5;
  width: 600px;
  height: 350px;
  margin-bottom: 10px;
  border: 1px solid red;
}

.cropper-box {
  /* width: 300px; */
  height: 350px;
  border: 1px solid green;
  width: 100%;
}

.cropper-dialog {
  display: flex;
  border: 1px solid blue;
}

.cropper-submit-box {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.submit-box {
  /* border: 1px solid green; */
  text-align: center;
  margin-top: 60px;
}

.cover-preview {
  width: 200px;
  height: calc(200px / 1.83);
  box-sizing: border-box;
  border-radius: 5px;
}

.editor-meta-input .el-input__inner {
  width: 200px;
  box-sizing: border-box;
}

.editor-meta-select {
  width: 200px;
}

.scoll-box {
  position: fixed;
  right: calc((100vw - 760px) / 2 - 130px);
  bottom: 80px;
  box-sizing: border-box;
  box-shadow: 0 1px 4px 0 rgb(227 226 226 / 60%);
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100px; */
  padding: 0 15px 0 13px;
  font-size: 14px;
  white-space: nowrap;
  line-height: 34px;
  border-radius: 18px;
  background-color: #fff;
  color: #9a9a9a;
  cursor: pointer;
}

.scoll-box-item {
  display: flex;
  align-items: center;
}

.scoll-icon {
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  margin-right: 8px;
}
</style>
