
  const actions = {
    getUserInfo ({ commit }) {
      commit('getUserInfo')
    },

    getAdminList ({ commit }, payload) {
      commit('getAdminList', payload)
    },

    getChapterList ({ commit }, payload) {
      commit('getChapterList', payload)
    },

    addChapter ({ commit }, payload) {
      commit('addChapter', payload)
    },

    removeChapter ({ commit }, payload) {
      commit('removeChapter', payload)
    },

    updateChapter ({ commit }, payload) {
      commit('updateChapter', payload)
    },

    addContent ({ commit }, payload) {
      commit('addContent', payload)
    },

    updateContent ({ commit }, payload) {
      commit('updateContent', payload)
    },

    removeContent ({ commit }, payload) {
      commit('removeContent', payload)
    },

    getSeries ({ commit }) {
      commit('getSeries')
    },

    addSeries ({ commit }, payload) {
      commit('addSeries', payload)
    },

    updateSeries ({ commit }, payload) {
      commit('updateSeries', payload)
    },

    getGoodsCategory ({ commit }) {
      commit('getGoodsCategory')
    },

    addGoodsCategory ({ commit }, payload) {
      commit('addGoodsCategory', payload)
    },

    updateGoodsCategory ({ commit }, payload) {
      commit('updateGoodsCategory', payload)
    },
  }

  export default actions