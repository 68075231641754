<template>
  <div class="container">
    <div :style="{ width: width * 6 + 'px', textAlign: 'left', fontSize: '24px', margin: '15px 0' }">
      <span>社区内容</span>
    </div>
    <el-card class="box-card" shadow="never">

      <el-table
        :data="list"
        style="width: 100%">
        <!-- <el-table-column
          fixed
          prop="id"
          label="序号"
          :width="width">
        </el-table-column> -->
        <el-table-column
          label="作者"
          style="border: 1px solid green;"
          :width="width">
          <template slot-scope="scope">
            <div>{{ scope.row.nickname }}</div>
            <img style="width: 40px; height: 40px;" v-if="scope.row" :src="scope.row.avatar" />
            <!-- <el-button type="text" size="small">编辑</el-button> -->
          </template>
        </el-table-column>

        <el-table-column
          label="内容"
          style="border: 1px solid green;"
          :width="width">
          <template slot-scope="scope">
            <div>{{ scope.row.content }}</div>
            <div class="memo-img-box">
              <div v-for="img of scope.row.images" :key="img">
                <img style="width: 60px; height: 60px;margin-left: 10px;" v-if="scope.row" :src="img.url" />
              </div>
            </div>
            <!-- <el-button type="text" size="small">编辑</el-button> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="create_at"
          label="时间"
          :width="width">
        </el-table-column>

        <el-table-column
          prop="is_publish"
          label="状态"
          :width="width">
          <template slot-scope="scope">
            <div :style="{ color: scope.row.is_publish === 1 ? 'green' : '' }">{{ scope.row.is_publish === 1 ? '已发布' : '未发布' }}</div>
          </template>
        </el-table-column>

        <el-table-column
          fixed="right"
          label="操作"
          :width="width">
          <template slot-scope="">
            <!-- <el-button v-if="!scope.row.is_publish" @click="handlePublish(scope.row)" type="text" size="small">发表</el-button>
            <el-button @click="handleEdit(scope.row)" type="text" size="small">修改</el-button>
            <el-button @click="handleDetele(scope.row)" type="text" style="color: red;" size="small">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import api from '@/api'
import { imgDomain } from '@/config'
import { format } from '@/common/time'
export default {
  name: 'MyCourse',
  data () {
    return {
      imgDomain,
      list: [],
      width: (window.outerWidth - 350) / 6,
      user: {}
    }
  },

  props: {
    msg: String
  },

  methods: {
    goTo (path) {
      this.$router.push({
        path
      })
    },

    async handlePublish (news) {
      const json = await api.publishNews(news.news_id)
      if (json.ret !== 1000) {
        alert('请重试试')
        return
      }
  
      console.log(json)
      location.reload()
    },

    async handleDetele (news) {
      const json = await api.deleteNews(news.news_id)
      if (json.ret !== 1000) {
        alert('请重试试')
        return
      }
  
      console.log(json)
      location.reload()
    },

    handleEdit (news) {},

    openDetail (row) {
      console.log(row)
      this.$router.push({
        path: `/course/detail?id=${row.id}`
      })
    },

    openEdit (row) {
      this.$router.push({
        path: `/course/add?id=${row.id}`
      })
    },

    goToAdd () {
      this.$router.push({
        path: '/course/add'
      })
    },

    async getMemoList () {
      try {
        const json = await api.getMemoList(1, 30)
        console.log(json, '输出结果')
        const data = json.data
        let list = data.list
        list = list.map(item => {
          item.create_at = format(new Date(+item.create_at * 1000), 'yyyy-MM-dd hh:mm')
          item.avatar = item.avatar ? item.avatar : 'image/132.jpeg'
          if (item.avatar.indexOf('https://') === -1) {
            item.avatar = imgDomain + item.avatar
          }
          let images = JSON.parse(item.images)
          images = Array.isArray(images) ? images : []
          images = images.map(item => {
            if (typeof item === 'string') {
              return {
                url: imgDomain + item,
                video: ''
              }
            }

            return {
              url: imgDomain + item.url,
              video: item.video ? imgDomain + item.video : ''
            }
          })

          item.images = images

          return item
        })
        this.list = list
      } catch (e) {
        //
        console.log(e)
      }
    }
  },

  created () {
    this.getMemoList()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.cell {
  display: flex;
  border: 1px solid green !important;
}

.mask-layer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  background: #000000;
  filter: alpha(opacity=50);
  -moz-opacity: 0.5;
  opacity: 0.5;
}

.mask-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
  box-sizing: border-box;
}

.add-box, .qr-box {
  position: relative;
  z-index: 9999;
  background: #fff;
  width: 650px;
  /* height: 500px; */
  box-sizing: border-box;
  opacity: 1;
  padding: 20px;
  text-align: left;
}

.add-box-head {
  margin: auto;
  width: 610px;
  display: flex;
  justify-content: space-between;
}

.el-icon-close {
  font-size: 22px;
  cursor: pointer;
}

.el-form {
  margin: 30px 0;
}

.el-input {
  width: 250px;
}

.add-footer {
  width: 610px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #EEE;
  padding-top: 20px;
}

.qr-code-box {
  width: 610px;
  box-sizing: border-box;
  /* border: 1px solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.memo-img-box {
  display: flex;
  flex-wrap: wrap;
}
</style>
