// 引入ali-oss
let OSS = require('ali-oss')

let client = new OSS({
  region: "oss-cn-shenzhen",
  secure: false,
  accessKeyId: 'LTAI5t9NYeUeXtS8kh6ot8Uw',
  accessKeySecret: 'Ouavpbe710mxj62l6UE19UQTiP6Xgo',
  endpoint: 'https://oss-cn-shenzhen.aliyuncs.com',
  bucket: 'ac-static-2022'
})

console.log(client)


export const putFile = async (ObjName, fileUrl) => {  
  try {    
    let result = await client.put(`${ObjName}`, fileUrl)    
    // ObjName为文件名字,可以只写名字，就直接储存在 bucket 的根路径，如需放在文件夹下面直接在文件名前面加上文件夹名称    
    return result  
  } catch (e) {    
   console.log(e)  
  }
  console.log(ObjName, fileUrl)
}

// 上传成功之后，转换真实的地址
// export const signatureUrl= async (ObjName) => {    
//   try {        
//     let result = await client.signatureUrl(`${ObjName}`)    
//     return result  
//   } catch (e) {    
//     console.log(e)  
//   }
// }