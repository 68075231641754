<template>
  <div class="container">
    <div :style="{ width: width * 3 + 'px', textAlign: 'left', fontSize: '24px', margin: '15px 0' }">
      <span>课程页面的方块内容</span>
    </div>
    <el-card class="box-card" shadow="never">
        <el-button plain v-for="(item, i) of list" :key="item" @click="handleOpenEdit(i + 1)">{{ item }}</el-button>
    </el-card>
  </div>
</template>

<script>

export default {
  name: 'CourseBlock',
  data () {
    return {
      width: (window.outerWidth - 350) / 3,
      list: ['爱创创新体系', '爱创思维塔', '爱创知识框架', '爱创愿景']
    }
  },

  props: {
    msg: String
  },

  computed: {
  },

  methods: {
    handleOpenEdit (id) {
      this.$router.push({
        path: `/course/edit_block?id=${id}`
      })
    }
  },

  created () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.course-cover {
}
</style>
