<template>
  <div class="container">
    
    <div class="add-box" :style="{ width: width + 'px' }">
    <el-page-header @back="goBack" content="新增资讯">
    </el-page-header>
      <!-- <div class="add-box-head">
        <div style="font-size: 22px;">添加课程</div>
        <div class="el-icon-close" @click="hideMaskHandle"></div>
      </div> -->
      <el-form :model="ruleForm" :rules="rules" label-width="80px" class="demo-ruleForm">
        <el-form-item label="标题" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>

        <el-form-item label="作者" prop="author">
          <el-input v-model="ruleForm.author"></el-input>
        </el-form-item>
  
        <el-form-item label="资讯封面" prop="cover">
          <label for="upload-file">点击上传封面</label>
          <input v-if="!cover" id="upload-file" class="filepath" title="" @change="changepic" accept="image/*" type="file"><br>
          <img v-if="cover.indexOf('image/') !== -1" :src="imgDomain + cover" />
        </el-form-item>

        <el-form-item label="内容" prop="desc">
          <div style="border: 1px solid #ccc;" class="editor-box">
              <Toolbar
                  style="border-bottom: 1px solid #ccc"
                  :editor="editor"
                  :defaultConfig="toolbarConfig"
                  :mode="mode"
              />
              <Editor
                  style="height: 700px; width: 600px; overflow-y: hidden;"
                  v-model="html"
                  :defaultConfig="editorConfig"
                  :mode="mode"
                  @onCreated="onCreated"
                  @onChange="onChange"
              />
          </div>
        </el-form-item>
        <div class="submit-box">
          <el-button type="primary" @click="submit">保存</el-button>
        </div>
      </el-form>

      <el-dialog title="裁剪封面" class="cropper-dialog" :visible.sync="showMask">
        <div class="cropper-box">
          <vue-cropper
            ref="cropper"
            class="vue-cropper"
            autoCrop
            canMoveBox
            canMove
            fixed
            centerBox
            :fixedNumber="[1.81, 1]"
            :img="cover"
          ></vue-cropper>
          <div class="cropper-submit-box">
            <el-button type="primary" @click="cropper">完成</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import config1 from '@/config'
import { VueCropper }  from 'vue-cropper'
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { imgDomain } from '@/config'
import { mapState } from 'vuex'

export default {
  name: 'AdminList',
  data () {
    return {
      imgDomain,
      admins: [],
      width: window.outerWidth - 350,
      ruleForm: {
        title: '',
        author: '',
        cover: '',
        content: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
          { min: 2, max: 200, message: '长度在 2 到 200 个字符', trigger: 'blur' }
        ],
        cover: [{
          required: true
        }],
        desc: [{
          required: true
        }]
      },
      user: {},
      options: [],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList: [],
      dialogCropperVisible: false,
      cover: '',
      showMask: false,
      descList: [],
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
      editor: null,
      html: '',
      toolbarConfig: { },
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default', // or 'simple'
    }
  },


  props: {
    msg: String
  },

  computed: {
    ...mapState([
      'series'
    ])
  },

  components: {
    VueCropper,
    Editor,
    Toolbar
  },

  methods: {
    changepic (e) {
      console.log(e.target.files[0])
      this.cover = URL.createObjectURL(e.target.files[0])
      this.showMask = true
    },

    onCreated(editor) {
      const editor1 = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      this.editor = editor1
    },

    onChange (editor) {
      console.log(editor.getHtml())
    },

    goBack () {
      this.$router.back()
    },

    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm() {
      let inputValue = this.inputValue
      if (inputValue) {
        this.dynamicTags.push(inputValue)
      }

      this.inputVisible = false
      this.inputValue = ''
    },

    handleRemove(file) {
      console.log(file)
      this.fileList = []
    },

    handleDescRemove (file) {
     this.descList = this.descList.filter(item => item.uid !== file.uid)
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      console.log(this.fileList)
    },

    handleDownload(file) {
      console.log(file);
    },

    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3)
      this.cover = this.fileList[0].url
      console.log(this.cover, 'ttt')
      this.showMask = true
    },

    handleDescChange(file, fileList) {
      this.descList = fileList
      console.log(this.descList, file)
      this.uploadDesc(file.raw, file.uid)
    },

    cropper () {
      this.$refs.cropper.getCropData(data => {
        console.log(this.fileList[0])
        this.uploadCover(this.dataURLtoFile(data, 'jpg'))
        this.showMask = false
      })
    },

    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime })
    },

    async uploadCover (file) {
      const json = await api.upload(file)
      const cover = json.data
      console.log(json)
      if (cover) {
        this.cover = cover
      }
    },

    async uploadDesc (file, uid) {
      const json = await api.upload(file)
      const desc = json.data
      console.log(desc, uid)
      const list = this.descList
      list.forEach((item) => {
        if (item.uid === uid) {
          item.path = desc
        }
      })

      this.descList = list
    },

    hideMaskHandle () {
      this.showMask = false
      this.user = {}
    },

    showMaskHandle () {
      this.showMask = true
    },

    handleClick (row) {
      console.log(row)
      this.showMaskHandle()
      this.user = row
    },

    async submit () {
      console.log(this.cover, this.ruleForm, this.content)
    
      if (!this.cover || !this.ruleForm.title) {
        this.$message({
          message: '请填写必填的信息',
          type: 'warning'
        })
        return
      }

      // title, author, cover, content
      console.log(this.html, this.ruleForm)
      const json = await api.addNews(this.ruleForm.title, this.ruleForm.author, this.cover, this.html)
      if (json.ret !== 1000) {
        this.$message({
          message: '出错了，请刷新重试',
          type: 'error'
        })
        return
      }

      location.href = '/news/list'
    }
  },

  created () {

    const editorConfig = { MENU_CONF: {} }
    const imageConfig = {}
    const videoConfig = {}
    const config = {
      fieldName: 'file',
      timeout: 50 * 1000,
      server: `${config1.api}/upload`,
      headers: {
      },
      customInsert: (res, cb) => {
        console.log(res)
        cb(config1.imgDomain + res.data)
      },
      onError: (file, err, res) => {
        this.$notify(err, 'error')
        console.log(res)
      }
    }
    Object.assign(imageConfig, config)
    Object.assign(videoConfig, config)
    imageConfig.maxFileSize = 20 * 1024 * 1024
    videoConfig.maxFileSize = 50 * 1024 * 1024
    imageConfig.meta = {
      fileName: '富文本编辑器',
      appCode: 'system',
      pathName: 'edit/image'
    }
    videoConfig.meta = {
      fileName: '富文本编辑器',
      appCode: 'system',
      pathName: 'edit/video'
    }
    imageConfig.onError = (file, err, res) => {
      console.log(res)
      if (err.message.indexOf('maximum') > -1) this.$notify({ title: '图片超出限制，最大20M', type: 'error' })
    }
    videoConfig.onError = (file, err, res) => {
      console.log(res)
      if (err.message.indexOf('maximum') > -1) this.$notify({ title: '视频超出限制，最大50M', type: 'error' })
    }
    editorConfig.MENU_CONF['uploadImage'] = imageConfig
    editorConfig.MENU_CONF['uploadVideo'] = videoConfig
    this.editorConfig = editorConfig
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.container {
  /* margin: auto; */
  /* border: 1px solid green; */
  display: flex;
}

.add-box {
  background: #fff;
  width: 650px;
  /* height: 500px; */
  box-sizing: border-box;
  opacity: 1;
  padding: 20px;
  margin-bottom: 150px;
  text-align: left;
}

.add-box-head {
  margin: auto;
  width: 610px;
  display: flex;
  justify-content: space-between;
}

.el-form {
  margin: 30px 0;
}

.el-input {
  width: 400px;
}

.vue-cropper {
  z-index: 3;
  width: 100%;
  height: 50vh;
  margin-bottom: 20px;
}

.cropper-box {
  /* width: 300px; */
  /* height: 300px; */
  /* border: 1px solid green; */
  width: 100%;
}

.cropper-dialog {
  display: flex;
}

.cropper-submit-box {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.submit-box {
  /* border: 1px solid green; */
  text-align: center;
  margin-top: 60px;
}

  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }

  label {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 5px;
    color: #333;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
  }

  input {
      display: block;
      margin-top: 100px;
    }
  #upload-file {
    display: none;
  }

  .editor-box {
    width: 600px;
  }
</style>
