<template>
  <div class="container">
    <div :style="{ width: width * 6 + 'px', textAlign: 'left', fontSize: '24px', margin: '15px 0' }">
      <span>全部课程</span>
    </div>
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix" :style="{ width: width * 6 + 'px' }">
        <!-- <span>我的课程</span> -->
        <el-button type="primary" style="float: right;" @click="goToAdd">添加课程</el-button>
      </div>

      <el-table
        :data="list"
        style="width: 100%">
        <!-- <el-table-column
          fixed
          prop="id"
          label="序号"
          :width="width">
        </el-table-column> -->
        <el-table-column
          label="封面"
          class="course-info"
          style="border: 1px solid green;"
          :width="width">
          <template slot-scope="scope">
            <img :style="{ width: width + 'px' }" v-if="scope.row" :src="imgDomain + scope.row.cover" />
            <!-- <el-button type="text" size="small">编辑</el-button> -->
          </template>
        </el-table-column>

        <el-table-column
          prop="title"
          label="名称"
          :width="width">
        </el-table-column>
        <el-table-column
          prop="difficulty"
          label="课程难度"
          :width="width">
        </el-table-column>
        <el-table-column
          prop="age"
          label="适合年龄"
          :width="width">
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          :width="width">
          <template slot-scope="scope">
            <div :style="{ color: scope.row.status === '已绑定' ? 'green' : '' }">{{ scope.row.status }}</div>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          :width="width">
          <template slot-scope="scope">
            <el-button @click="openDetail(scope.row)" type="text" size="small">查看</el-button>
            <el-button @click="openEdit(scope.row)" type="text" size="small">修改</el-button>
            <el-button @click="removeCourse(scope.row)" type="text" style="color: red;" size="small">删除</el-button>
            <!-- <el-button type="text" size="small">编辑</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import api from '@/api'
import { imgDomain } from '@/config'
export default {
  name: 'MyCourse',
  data () {
    return {
      imgDomain,
      list: [],
      width: (window.outerWidth - 350) / 6,
      user: {}
    }
  },

  props: {
    msg: String
  },

  methods: {
   async removeCourse (row) {
      console.log(row)
      try {
        this.$confirm(`确认删除 ${row.title}？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
            const json = await api.removeCourse(row.id)
            if (json.ret !== 1000) {
              this.$message({
                type: 'info',
                message: '删除失败'
              })
              return
            }
            this.list = this.list.filter(item => item.id !== row.id)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      } catch (e) {
        console.log(e)
      }
    },

    openDetail (row) {
      console.log(row)
      this.$router.push({
        path: `/course/detail?id=${row.id}`
      })
    },

    openEdit (row) {
      this.$router.push({
        path: `/course/add?id=${row.id}`
      })
    },

    goToAdd () {
      this.$router.push({
        path: '/course/add'
      })
    },

    async getMyCourse () {
      try {
        const json = await api.getMyCourse()
        console.log(json, '输出结果')
        const list = json.data
        this.list = list
      } catch (e) {
        //
      }
    }
  },

  created () {
    this.getMyCourse()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.cell {
  display: flex;
  border: 1px solid green !important;
}

.mask-layer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  background: #000000;
  filter: alpha(opacity=50);
  -moz-opacity: 0.5;
  opacity: 0.5;
}

.mask-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
  box-sizing: border-box;
}

.add-box, .qr-box {
  position: relative;
  z-index: 9999;
  background: #fff;
  width: 650px;
  /* height: 500px; */
  box-sizing: border-box;
  opacity: 1;
  padding: 20px;
  text-align: left;
}

.add-box-head {
  margin: auto;
  width: 610px;
  display: flex;
  justify-content: space-between;
}

.el-icon-close {
  font-size: 22px;
  cursor: pointer;
}

.el-form {
  margin: 30px 0;
}

.el-input {
  width: 250px;
}

.add-footer {
  width: 610px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #EEE;
  padding-top: 20px;
}

.qr-code-box {
  width: 610px;
  box-sizing: border-box;
  /* border: 1px solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
