<template>
  <div class="home">
    <div class="data-container">
      <div class="data-card add-card">
        <div style="text-align: left;">新增内容</div>
        <div style="margin-top: 15px;">
          <el-button>商品</el-button>

          <el-button @click="goTo('/news/add')">资讯</el-button>
        </div>
      </div>

      <div class="data-card" :style="{ width: dataWidth + 'px' }">
        <div class="shop-data-item">
          <div class="shop-data-title">昨日订单</div>
          <div class="shop-data-count">12</div>
        </div>
        <div class="shop-data-item">
          <div class="shop-data-title">今日订单</div>
          <div class="shop-data-count">10</div>
        </div>
        <div class="shop-data-item">
          <div class="shop-data-title">待处理订单</div>
          <div class="shop-data-count" style="color: #409EFF;">5</div>
        </div>
      </div>
    </div>

    <div class="recent-container">
      <div>
        <div class="recent-container-header">
          <div>最近订单</div>
          <el-button>全部订单</el-button>
        </div>
        <div style="margin-top: 20px; color: #888">暂无最近订单</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      width: window.outerWidth - 350,
      dataWidth: (window.outerWidth - 350 - 250)
    }
  },
  props: {
    msg: String
  },

  methods: {
    goTo (path) {
      this.$router.push({
        path
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home {
  /* border: 1px solid green; */
  margin: 0;
}


.data-container {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.add-card {
  flex-direction: column !important;
  width: 250px;
  box-sizing: border-box;
}

.recent-container {
  background: #fff;
  border: 1px solid #EBEEF5;
  margin-top: 20px;
  padding: 15px;
  box-sizing: border-box;
}

.data-row:first-child {
  margin-top: 0;
}

.data-card {
  border: 1px solid #EBEEF5;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 15px;
  box-sizing: border-box;
}

.el-card__body {
  display: flex !important;
  border: 1px solid red;
}

.shop-data-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1a1b1c;
}

.shop-data-count {
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}

.recent-container-header {
  display: flex;
  justify-content: space-between;
}
</style>
