<template>
  <div class="container">
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <!-- <span>卡片名称</span> -->
        <el-button type="primary" style="float: right;" @click="showMaskHandle">新增成员</el-button>
      </div>

      <el-table
        :data="admins"
        style="width: 100%">
        <el-table-column
          fixed
          prop="uid"
          label="序号"
          :width="width">
        </el-table-column>
        <el-table-column
          fixed
          prop="name"
          label="姓名"
          :width="width">
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机"
          :width="width">
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          :width="width">
          <template slot-scope="scope">
            <div :style="{ color: scope.row.status === '已绑定' ? 'green' : '' }">{{ scope.row.status }}</div>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          :width="width">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">绑定</el-button>
            <!-- <el-button type="text" size="small">编辑</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <div class="mask-container" v-if="showMask">
      <div class="mask-layer"></div>
      <template v-if="!user.name">
        <div class="add-box">
          <div class="add-box-head">
            <div style="font-size: 22px;">新增账号</div>
            <div class="el-icon-close" @click="hideMaskHandle"></div>
          </div>
          <div style="margin: 20px 0; font-size: 14px;">
            <span class="el-icon-info" style="color: #409eff;"></span>
            设置账号后，员工通过微信扫码进行绑定和登录。请输入真实的实名信息。
          </div>
          <el-form :model="ruleForm" :rules="rules" label-width="80px" class="demo-ruleForm">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="ruleForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="成员权限" prop="type">
              <el-checkbox-group v-model="ruleForm.type">
                <el-checkbox label="管理员" name="type"></el-checkbox>
                <el-checkbox label="教师" name="type"></el-checkbox>
                <el-checkbox label="财务" name="type"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
          <div class="add-footer">
            <el-button type="primary" @click="submit">下一步</el-button>
          </div>
        </div>
      </template>

      <template v-if="user.name">
        <div class="qr-box">
          <div class="add-box-head">
            <div style="font-size: 22px;">邀请成员绑定微信</div>
            <div class="el-icon-close" @click="hideMaskHandle"></div>
          </div>
          <div style="margin: 20px 0; font-size: 14px;">
            <span class="el-icon-info" style="color: #409eff;"></span>
              请截图发送二维码给 <span style="color: #409eff;">{{ user.name }}</span>，通过微信扫码进行绑定账号。
          </div>

          <div class="qr-code-box">
            <img :src="user.url" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import config from '@/config'

export default {
  name: 'AdminList',
  data () {
    return {
      admins: [],
      width: (window.outerWidth - 350) / 5,
      ruleForm: {
        name: '',
        phone: '',
        type: []
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        phone: [
          { type: 'string', min: 11, max: 11 }
        ],
        type: [
          { type: 'array', required: true, message: '请至少选择一个权限', trigger: 'change' }
        ]
      },
      showMask: false,
      user: {}
    }
  },

  props: {
    msg: String
  },

  methods: {
    hideMaskHandle () {
      this.showMask = false
      this.user = {}
    },

    showMaskHandle () {
      this.showMask = true
    },

    async submit () {
      console.log(this.ruleForm)
      const name = this.ruleForm.name
      const phone = this.ruleForm.phone
      const type = this.ruleForm.type
      const typeStr = type.join('')
      let is_teacher = 0
      let is_master = 0
      let is_finance = 0
  
      if (!name || type.length === 0) {
        return
      }

      if (typeStr.indexOf('教师') !== -1) {
        is_teacher = 1
      }

      if (typeStr.indexOf('管理员') !== -1) {
        is_master = 1
      }

      if (typeStr.indexOf('财务') !== -1) {
        is_finance = 1
      }

      const json = await api.addAdmin(name, phone, is_teacher, is_master, is_finance)
      const user = json && json.data

      if (!user) {
        return
      }

      user.url = config.QRCodeApi + '?ticket=' + user.ticket

      this.user = user
    },

    async getAdminList () {
      try {
        const json = await api.getAdminList(100, 0)
        console.log(json, '输出结果')
        const admins = (json && json.data && json.data.admins) || []
        admins.map(item => {
          item.url = config.QRCodeApi + '?ticket=' + item.ticket
          item.status = item.fuwuhao_openid ? '已绑定' : '待绑定'
          item.status = item.is_delete ? '已停用' : item.status
          return item
        })

        this.admins = admins
        console.log(admins)
      } catch (e) {
        //
      }
    },

    handleClick (row) {
      console.log(row)
      this.showMaskHandle()
      this.user = row
    }
  },

  created () {
    this.getAdminList()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.mask-layer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  background: #000000;
  filter: alpha(opacity=50);
  -moz-opacity: 0.5;
  opacity: 0.5;
}

.mask-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
  box-sizing: border-box;
}

.add-box, .qr-box {
  position: relative;
  z-index: 9999;
  background: #fff;
  width: 650px;
  /* height: 500px; */
  box-sizing: border-box;
  opacity: 1;
  padding: 20px;
  text-align: left;
}

.add-box-head {
  margin: auto;
  width: 610px;
  display: flex;
  justify-content: space-between;
}

.el-icon-close {
  font-size: 22px;
  cursor: pointer;
}

.el-form {
  margin: 30px 0;
}

.el-input {
  width: 250px;
}

.add-footer {
  width: 610px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #EEE;
  padding-top: 20px;
}

.qr-code-box {
  width: 610px;
  box-sizing: border-box;
  /* border: 1px solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
