import Vue from 'vue'
import Router from 'vue-router'
 
//组件模块
import Home from '@/components/Home'
import AdminList from '@/components/AdminList'
import MyCourse from '@/components/CourseList'
import AddCourse from '@/components/AddCourse'
import CourseList from '@/components/CourseList'
import CourseInfo from '@/components/CourseInfo'
import EditContent from '@/components/EditContent'
import EditCourse from '@/components/EditCourse'
import GoodsList from '@/components/GoodsList'
import AddGoods from '@/components/AddGoods'
import Series from '@/components/Series'
import CourseSwiper from '@/components/CourseSwiper'
import MemoSwiper from '@/components/MemoSwiper'
import ShopSwiper from '@/components/ShopSwiper'
import AddMemoSwiper from '@/components/AddMemoSwiper'
import AddShopSwiper from '@/components/AddShopSwiper'
import CourseBlock from '@/components/CourseBlock'
import AddNews from '@/components/AddNews'
import NewsList from '@/components/NewsList'
import AddCategory from '@/components/AddCategory'
import CategoryList from '@/components/CategoryList'

import MemoList from '@/components/MemoList'

Vue.use(Router)
 
const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', name: 'Home', component: Home },
    { path: '/user/admins', name: 'AdminList', component: AdminList },
    { path: '/course/my', name: 'MyCourse', component: MyCourse },
    { path: '/course/list', name: 'CourseList', component: CourseList },
    { path: '/course/series', name: 'Series', component: Series },
    { path: '/course/add', name: 'AddCourse', component: AddCourse },
    { path: '/course/edit', name: 'EditCourse', component: EditCourse },
    { path: '/course/detail', name: 'CourseInfo', component: CourseInfo },
    { path: '/course/swiper', name: 'CourseSwiper', component: CourseSwiper },
    { path: '/course/block', name: 'CourseBlock', component: CourseBlock },
    { path: '/content/edit', name: 'EditContent', component: EditContent },
    { path: '/memo/swiper', name: 'MemoSwiper', component: MemoSwiper },
    { path: '/memo/list', name: 'MemoList', component: MemoList },
    { path: '/memo/add_swiper', name: 'MemoSwiper', component: AddMemoSwiper },
    { path: '/shop/swiper', name: 'ShopSwiper', component: ShopSwiper },
    { path: '/shop/add_swiper', name: 'MemoSwiper', component: AddShopSwiper },
    { path: '/news/add', name: 'AddNews', component: AddNews },
    { path: '/news/list', name: 'NewsList', component: NewsList },
    { path: '/category/add', name: 'AddCategory', component: AddCategory },
    { path: '/xshop/category', name: 'CategoryList', component: CategoryList },
    { path: '/xshop/add_goods', name: 'AddGoods', component: AddGoods },
    { path: '/xshop/goods_list', name: 'GoodsList', component: GoodsList },
  ]
})

export default router