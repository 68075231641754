<template>
  <div id="app">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <router-view /> -->
    <template v-if="token">
      <Index v-if="token && status === 0" />
      <AddCourseBoxVue v-if="status === 1" />
      <AddContentBoxVue v-if="status === 2" />
      <EditCourseBlockVue v-if="status === 3" />
    </template>
    <Login v-if="!token" />
  </div>
</template>

<script>
import Login from './components/Login.vue'
import Index from './components/Index.vue'
import AddCourseBoxVue from './components/AddCourseBox.vue'
import AddContentBoxVue from './components/AddContentBox.vue'
import EditCourseBlockVue from './components/EditCourseBlock.vue'
import config from './config'
console.log(config)
// import store from '@/store'

export default {
  name: 'App',
  components: {
    Login,
    Index,
    AddCourseBoxVue,
    AddContentBoxVue,
    EditCourseBlockVue
  },
  data () {
    return {
      isEditor: false,
      status: 0
    }
  },

  watch:{
    $route(to, from){
      console.log(to.path, from, '路径彼岸花')
      this.updateEditorStatus(to.path)
    }
  },

  computed:{
     token () {
       return this.$store.state.token
     }
  },

  methods: {
    updateEditorStatus (path) {
      if (path === '/course/add') {
        this.status = 1
      }

      if (path === '/content/edit') {
        this.status = 2
      }

      if (path === '/course/edit_block') {
        this.status = 3
      }
    }
  },

  created () {
    console.log(this.$route.path, '路径啊啊啊啊啊啊')
    this.updateEditorStatus(this.$route.path)
    this.$store.dispatch('getUserInfo', {
      })

      // 获取系列课程
    this.$store.dispatch('getSeries', {})
    // 获取门店商品分类
    this.$store.dispatch('getGoodsCategory', {})
  }
}
</script>

<style>
#app {
  font-family: 14px/1.6 "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
}

body {
  margin: 0;
  padding: 0;
}
</style>
