<template>
  <div class="index">
    <el-container style="height: 100vh; border: 1px solid #eee">
      <!-- <el-header style="text-align: right; font-size: 12px">
        <div class="logo-box" @click="handleGoToHome">
          <img src="../assets/logo.jpeg" alt="" style="width: 40px; height: 40px; margin-right: 10px;">
          <div style="font-size: 1.2rem">爱创造物家</div>
        </div>
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            {{ user ? user.name : '我的账号' }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="setting">设置</el-dropdown-item>
            <el-dropdown-item command="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-header> -->
      <el-container>
        <el-aside class="sidebar" width="250px">
          <el-menu :default-active="this.$route.path" :default-openeds="['1', '/user', '/news', '/course', '/memo', '4', '/shop', '/xshop']" router>
            <el-menu-item index="/">
              <i class="el-icon-s-home"></i>
              <span slot="title">首页</span>
            </el-menu-item> 
            <el-submenu index="/user">
              <template slot="title"><i class="el-icon-s-custom"></i>用户管理</template>
              <el-menu-item index="/user/admins">成员管理</el-menu-item>
              <el-menu-item index="/user/students">学生管理</el-menu-item>
            </el-submenu>

            <el-submenu index="/news">
              <template slot="title"><i class="el-icon-s-management"></i>资讯管理</template>
              <el-menu-item index="/news/list">全部资讯</el-menu-item>
              <el-menu-item index="/course/trash">回收站</el-menu-item>
            </el-submenu>

            <el-submenu index="/course">
              <template slot="title"><i class="el-icon-s-management"></i>课程管理</template>
              <el-menu-item index="/course/list">全部课程</el-menu-item>
              <el-menu-item index="/course/series">系列管理</el-menu-item>
              <el-menu-item index="/course/block">方块内容</el-menu-item>
              <el-menu-item index="/course/swiper">轮播图</el-menu-item>
            </el-submenu>
            <el-submenu index="/memo">
              <template slot="title"><i class="el-icon-s-grid"></i>社区管理</template>
              <el-menu-item index="/memo/swiper">轮播图</el-menu-item>
              <el-menu-item index="/memo/list">内容</el-menu-item>
              <el-menu-item index="4-3">选项3</el-menu-item>
            </el-submenu>

            <el-submenu index="/xshop">
              <template slot="title"><i class="el-icon-s-goods"></i>门店商城</template>
              <el-menu-item index="/xshop/goods_list">商品</el-menu-item>
              <el-menu-item index="/xshop/category">分类</el-menu-item>
              <el-menu-item index="/xshop/orders">订单</el-menu-item>
            </el-submenu>

            <el-submenu index="/shop">
              <template slot="title"><i class="el-icon-s-goods"></i>商城管理</template>
              <el-menu-item index="/shop/swiper">轮播图</el-menu-item>
              <el-menu-item index="5-2">选项2</el-menu-item>
              <el-menu-item index="5-3">选项3</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>
        
        <el-main class="main">
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'Index',
  props: {
    msg: String
  },

  computed: {
    ...mapState([
      'user',
      'name'
    ])
  },

  methods: {
    handleCommand (command) {
      if (command === 'logout') {
        localStorage.clear()
        location.reload()
      }
    },

    handleOpenBlock (index) {
      // alert(index)
      this.$router.push({
        path: `/block/update?id=${index}`
      })
    },
    handleGoToHome () {
      this.$router.push({
        path: '/'
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .el-header {
    background-color: #fff;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 100px;
    position: fixed;
  }

  .logo-box {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .el-aside {
    color: #333;
    background: #f8f8f8;
  }

  .el-aside .el-menu, .el-submenu, .el-menu-item {
    background: #f8f8f8;
  }

  .el-main {
    background: #f8f8f8;
  }

  .el-dropdown {
    cursor: pointer;
  }

  .sidebar {
  position: fixed;
  height: 100%;
  overflow-y: auto;
}

.main {
  margin-left: 250px;
}

.el-menu-item.is-active {
  background: #ecf5ff !important;
  color: #409EFF;
}
</style>
