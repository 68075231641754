import config from '../config'
console.log(config.api)
const token = localStorage.getItem('token')

export default {
  get (path, requireUser, data = {}) {
    return new Promise((resolve, reject) => {
      try {
        let url = config.api + path
        if (requireUser) {
          data.token = token
        }

        const arr = Object.keys(data)
        let query = ''
        arr.forEach(key => {
          query = `${query}&${key}=${data[key]}`
        })

        url = url + '?' + query

        console.log(url, query)

        fetch(url).then(res => res.json())
        .catch(error => reject(error))
        .then(response => resolve(response))
      } catch (e) {
        reject(e)
      }
    })
  },

  post (path, data, requireUser) {
    if (requireUser) {
      data.token = token
    }

    return new Promise((resolve, reject) => {
      try {
        fetch(config.api + path, {
          method: 'POST', 
          body: JSON.stringify(data), 
          headers: new Headers({
            'Content-Type': 'application/json'
          })
        }).then(res => res.json())
        .catch(error => reject(error))
        .then(response => resolve(response))
      } catch (e) {
        reject(e)
      }
    })
  },

  upload (file) {
    const path = '/upload'
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append("file", file)
      const url = `${config.api}${path}`
      try {
        fetch(url, {
          method: "POST",
          body: formData
        }).then(res => res.json())
        .catch(error => reject(error))
        .then(response => resolve(response))
      } catch (e) {
        reject(e)
      }
    })
  }
}