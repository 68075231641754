

import fetch from '@/api/fetch'

async function getLoginQRCode () {
  const json = await fetch.get('/admin/qrcode')
  return json
}

async function login (ticket, qrType) {
  const json = await fetch.post('/admin/login', {
    ticket,
    qrType
  })
  return json
}

async function getAdminList (pageSize, pageNum) {
  const json = await fetch.get(`/admin/list`, true, { pageNum, pageSize })
  return json
}

async function addAdmin (name, phone, is_teacher, is_master, is_finance) {
  const json = await fetch.post(`/admin/add`, {
    name,
    phone,
    is_teacher,
    is_master,
    is_finance
  })
  return json
}


async function getCourseTypeList () {
  const json = await fetch.get(`/course/type_list`)
  return json
}

// this.ruleForm.sid, this.ruleForm.title, this.ruleForm.age, this.ruleForm.difficulty, this.cover, html
async function addCourse (sid, title, age, difficulty, cover, info, hide_title) {
  const json = await fetch.post(`/course/add`, {
    sid, title, age, difficulty, cover, info, hide_title
  }, true)
  return json
}


async function editCourse (sid, id, title, age, difficulty, cover, info, hide_title) {
  const json = await fetch.post(`/course/edit`, {
    sid, id, title, age, difficulty, cover, info, hide_title
  }, true)
  return json
}

async function editCourseSwiper (id, is_swiper, swiper_index) {
  const json = await fetch.post(`/course/edit_swiper`, {
    id, is_swiper, swiper_index
  }, true)
  return json
}

async function removeCourse (id) {
  const json = await fetch.post(`/course/remove`, {
    id
  }, true)
  return json
}

async function getMyCourse () {
  const json = await fetch.get(`/course/my_course`, true)
  return json
}

async function getAllCourse () {
  const json = await fetch.get(`/course/list`, true)
  return json
}

async function getCourseInfo (id) {
  const json = await fetch.get(`/course/info`, true, { id })
  return json
}

async function getBlock (id) {
  const json = await fetch.get(`/course/get_block`, true, { id })
  return json
}

async function updateBlock (id, title, html) {
  const json = await fetch.post(`/course/update_block`, {
    id, title, html
  }, true)
  return json
}

async function upload (file) {
  const json = await fetch.upload(file)
  return json
}

async function getSeries () {
  const json = await fetch.get(`/course/series`, true, {})
  return json
}

async function addSeries (name) {
  const json = await fetch.post(`/course/add_series`, {
    name
  }, true)
  return json
}

async function updateSeries (sid, name) {
  const json = await fetch.post(`/course/update_series`, {
    name,
    sid
  }, true)
  return json
}

async function addChapter (title, course_id) {
  const json = await fetch.post(`/chapter/add`, {
    title, course_id
  }, true)
  return json
}

// title, video, content, content_type, course_id, chapter_id
async function addContent (title, video, content, content_type, course_id, chapter_id) {
  const json = await fetch.post(`/content/add`, {
    title, video, content, content_type, course_id, chapter_id
  }, true)
  return json
}

// content_id
async function getContent (content_id) {
  const json = await fetch.get(`/content/info`, true, { content_id })
  return json
}

// title, video, content, content_type, content_id
async function updateContent (title, video, content, content_type, content_id) {
  const json = await fetch.post(`/content/update`, {
    title, video, content, content_type, content_id
  }, true)
  return json
}

//course_id, content_id, chapter_id
async function removeContent (course_id, content_id, chapter_id) {
  const json = await fetch.post(`/content/remove`, {
    course_id, content_id, chapter_id
  }, true)
  return json
}

// course_id, chapter_id
async function removeChapter (course_id, chapter_id) {
  const json = await fetch.post(`/chapter/remove`, {
    course_id, chapter_id
  }, true)
  return json
}

// title, course_id, chapter_id
async function updateChapter (title, course_id, chapter_id) {
  const json = await fetch.post(`/chapter/update`, {
    title, course_id, chapter_id
  }, true)
  return json
}

async function getChapterList (course_id) {
  const json = await fetch.get(`/chapter/list`, true, { course_id })
  return json
}

// name, cover, images, video, price, detail
async function addGoods (name, cover, images, video, price, detail, category_id, stock) {
  const json = await fetch.post(`/goods/add`, {
    name, cover, images, video, price, detail, category_id, stock
  }, true)
  return json
}

async function getGoodsList (pageIndex, pageSize) {
  const json = await fetch.get(`/goods/list`, true, { pageIndex, pageSize })
  return json
}

async function publishGoods (id) {
  const json = await fetch.post(`/goods/publish`, {
    id
  }, true)
  return json
}

async function unpublishGoods (id) {
  const json = await fetch.post(`/goods/unpublish`, {
    id
  }, true)
  return json
}

async function getGoodsInfo (id) {
  const json = await fetch.get(`/goods/info`, true, { id })
  return json
}

async function editGoods (id, name, cover, images, video, price, detail, category_id, stock) {
  const json = await fetch.post(`/goods/edit`, {
    id, name, cover, images, video, price, detail, category_id, stock
  }, true)
  return json
}

// name
async function addCategory (name) {
  const json = await fetch.post(`/goods/add_category`, {
    name
  }, true)
  return json
}

// 获取分类
async function getCategoryList () {
  const json = await fetch.get(`/goods/category_list`, true, { })
  return json
}

// 获取分类详情
async function getCategory (id) {
  const json = await fetch.get(`/goods/get_category`, true, { id })
  return json
}

// 修改分类
async function editCategory (id, name, tips) {
  const json = await fetch.post(`/goods/edit_category`, {
   id, name, tips
  }, true)
  return json
}

// 添加资讯
async function addNews (title, author, cover, content) {
  const json = await fetch.post(`/news/add`, {
    title, author, cover, content
  }, true)
  return json
}

async function getNewsList (pageIndex, pageSize) {
  const json = await fetch.get(`/news/list`, true, { pageIndex, pageSize })
  return json
}

async function deleteNews (news_id) {
  const json = await fetch.post(`/news/delete`, {
    news_id
  }, true)
  return json
}

async function publishNews (news_id) {
  const json = await fetch.post(`/news/publish`, {
    news_id
  }, true)
  return json
}

async function addSwiper (title, cover, url, type) {
  const json = await fetch.post(`/swiper/add`, {
    title, cover, url, type
  }, true)
  return json
}

async function getSwiperList (type) {
  const json = await fetch.post(`/swiper/list`, {
    type
  }, true)
  return json
}

async function getSwiper (id) {
  const json = await fetch.post(`/swiper/get`, {
    id
  }, true)
  return json
}

// title, cover, url, status, id
async function updateSwiperList (title, cover, url, status, id) {
  const json = await fetch.post(`/swiper/update`, {
    title, cover, url, status, id
  }, true)
  return json
}

async function getMemoList (pageNum, pageSize) {
  const json = await fetch.get(`/memo/list`, true, { pageNum, pageSize })
  return json
}

export default {
  getLoginQRCode,
  login,
  getAdminList,
  addAdmin,
  getCourseTypeList,
  addCourse,
  editCourse,
  editCourseSwiper,
  removeCourse,
  getMyCourse,
  getAllCourse,
  getCourseInfo,
  getBlock,
  updateBlock,
  upload,
  addChapter,
  getContent,
  addContent,
  updateContent,
  removeContent,
  removeChapter,
  updateChapter,
  getChapterList,
  getSeries,
  addSeries,
  updateSeries,
  addGoods,
  unpublishGoods,
  publishGoods,
  getGoodsList,
  getGoodsInfo,
  editGoods,
  addCategory,
  getCategoryList,
  getCategory,
  editCategory,
  addNews,
  getNewsList,
  deleteNews,
  publishNews,
  addSwiper,
  getSwiper,
  getSwiperList,
  updateSwiperList,
  getMemoList
}