<template>
  <div class="add-course-container container" ref="main">
    <div class="course-editor-box">
      <ToolBarVue v-if="editor" class="editor-toolbar" :editor="editor" />
      
      <div class="scoll-box" v-if="scrollStatus !== 1">
        <div @click="toTop" class="scoll-box-item" v-if="scrollStatus === 2">
          <img class="scoll-icon" src="../assets/toBack.png" />
          <div>回到顶部</div>
        </div>
        <div @click="toBottom" class="scoll-box-item" v-if="scrollStatus === 0">
          <img class="scoll-icon" src="../assets/toBottom.png" />
          <div>课程设置</div>
        </div>
      </div>

      <el-input v-model="ruleForm.title" class="name" placeholder="在这里输入课程名称"></el-input>

      <editor-content :editor="editor" v-model="courseDesc" />
      <div class="editor-meta-box">
        <div style="font-size: 18px;font-weight: bold; padding-bottom: 10px;">课程设置</div>
        <div class="editor-meta-upload-img-box">
          <div style="font-size: 14px; color: #606266;">添加封面</div>
          <div class="editor-meta-upload-img" v-if="cover.indexOf('image/') === -1">
            <label class="upload-cover" for="upload-file1">添加课程封面</label>
            <input id="upload-file1" class="filepath img-input" title="" @change="handleUploadImg" accept="image/*" type="file"><br>
          </div>

          <div class="editor-meta-upload-img-r" v-if="cover.indexOf('image/') !== -1">
            <label class="upload-cover-r" for="upload-file2">更换</label>
            <input  id="upload-file2" class="filepath img-input" title="" @change="handleUploadImg" accept="image/*" type="file">
            <img  class="cover-preview" :src="imgDomain + cover" />
          </div>
        </div>

        <el-form :model="ruleForm" :rules="rules" label-width="80px" class="demo-ruleForm">
          <el-form-item label="适合年龄" prop="age">
            <el-input class="editor-meta-input" v-model="ruleForm.age"></el-input>
          </el-form-item>

          <el-form-item label="课程难度" prop="difficulty">
            <el-input class="editor-meta-input" v-model="ruleForm.difficulty"></el-input>
          </el-form-item>

          <el-form-item label="课程系列" prop="sid">
            <el-select class="editor-meta-select" v-model="ruleForm.sid" placeholder="请选择系列">
              <el-option
                v-for="item in series"
                :key="item.sid"
                :label="item.name"
                :value="item.sid">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="隐藏标题" prop="hide_title">
            <el-switch
              v-model="ruleForm.hide_title"
              active-text=""
              inactive-text="">
            </el-switch>
          </el-form-item>
        </el-form>
      </div>

      <div class="editor-footer-box">
          <el-popconfirm
            :title="this.id ? '将丢失本次修改，确定取消？' : '取消将丢失正在编辑的内容，确定取消？'"
            @confirm="handleCancel"
            style="margin-right: 20px"
          >
            <el-button slot="reference">取消</el-button>
          </el-popconfirm>
        <!-- <el-button >取消</el-button> -->
        <el-button type="primary" @click="submit">完成</el-button>
      </div>
    </div>



    <el-dialog title="裁剪封面" class="cropper-dialog" :visible.sync="showMask">
      <div class="cropper-box">
        <vue-cropper
          ref="cropper"
          class="vue-cropper"
          autoCrop
          canMoveBox
          canMove
          fixed
          original
          centerBox
          autoCropWidth
          :fixedNumber="[1.83, 1]"
          :img="coverTemp"
        ></vue-cropper>
        <div class="cropper-submit-box">
          <el-button @click="handleCancelCover">取消</el-button>
          <el-button type="primary" @click="cropper">完成</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import api from '@/api'
// import config1 from '@/config'
import { VueCropper }  from 'vue-cropper'
import '@wangeditor/editor/dist/css/style.css'
// import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { imgDomain } from '@/config'
import { mapState } from 'vuex'
import api from '@/api'

import { EditorContent } from '@tiptap/vue-2'

import ToolBarVue from '@/common/ToolBar.vue';
import initEditor from '@/common/editor';

export default {
  name: 'AdminList',
  data () {
    return {
      scrollStatus: 0,
      editor: null,
      imgDomain,
      admins: [],
      width: window.outerWidth - 350,
      name: '',
      courseDesc: '',
      cover: '',
      coverTemp: '',
      showMask: false,
      ruleForm: {
        title: '',
        age: '',
        difficulty: '',
        course_type: '',
        time: '',
        number: '',
        cover: '',
        desc: '',
        tags: '',
        price: '',
        sid: '',
        hide_title: 0
      },
      rules: {
        sid: [
          { required: true, message: '选择课程所属系列', trigger: 'blur' }
        ],
        age: [{
          required: true, message: '输入课程适合的年龄'
        }],
        difficulty: [{
          required: true, message: '输入课程难度',
        }]
      },
    }
  },


  props: {
    msg: String
  },

  mounted() {
    // this.editor.options.extensions[1].options.placeholder = "My own placeholder :D"
    // this.editor.chain().focus().setFontSize('16px').run()
  },

  beforeUnmount() {
    this.editor.destroy()
    //
  },

  computed: {
    ...mapState([
      'series'
    ])
  },

  components: {
    EditorContent,
    ToolBarVue,
    VueCropper
  },

  methods: {
    toTop () {
      //参数i表示间隔的幅度大小，以此来控制速度
      let c = null
      const i = 50
      document.documentElement.scrollTop -= i
      if (document.documentElement.scrollTop > 0) {
        c = setTimeout(()=> this.toTop(i), 16)
      } else {
        clearTimeout(c)
      }
    },

    toBottom () {
      const i = 50
      const clientHeight = document.documentElement.clientHeight||document.body.clientHeight
      const scrollHeight = document.documentElement.scrollHeight
      const height = scrollHeight - clientHeight //超出窗口上界的值就是底部的scrolTop的值
      let c = null
      document.documentElement.scrollTop += i
      if (document.documentElement.scrollTop < height) {
        c= setTimeout(()=> this.toBottom(i), 16)
      } else {
        clearTimeout(c)
      }
    },

    handleUploadImg (e) {
      console.log(e.target.files[0])
      this.coverTemp = URL.createObjectURL(e.target.files[0])
      this.showMask = true
    },

    handleCancelCover () {
      if (this.cover.indexOf('-') === -1) {
        // this.cover = '' 
      }

      this.showMask = false
    },

    handleCancel () {
      location.href = '/course/list'
    },

    handleNext () {
      console.log(this.courseDesc, this.name, this.editor.getHTML())
    },

    async changepic (e) {
      console.log(e.target.files[0])
      // const img = URL.createObjectURL(e.target.files[0])
      const file = e.target.files[0]

      const json = await api.upload(file)
      const path = json.data
      console.log(json)
      if (path) {
        const url = imgDomain + json.data
        this.editor.chain().focus().setImage({ src: url }).run()
      }
    },

    async addImage() {
      const img = window.prompt('URL')
      const file = this.dataURLtoFile(img, 'jpg')

      const json = await api.upload(file)
      const path = json.data
      console.log(json)
      if (path) {
        const url = imgDomain + json.data
        this.editor.chain().focus().setImage({ src: url }).run()
      }
    },

    cropper () {
      this.$refs.cropper.getCropData(data => {
        this.uploadCover(this.dataURLtoFile(data, 'jpg'))
        this.showMask = false
      })
    },

    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime })
    },

    async uploadCover (file) {
      const json = await api.upload(file)
      const cover = json.data
      console.log(json)
      if (cover) {
        this.cover = cover
      }
    },

    async getCourseInfo () {
      const id = this.id
      const json = await api.getCourseInfo(id)
      console.log(json)

      const course_info = json.data

      if (!course_info) {
        location.href = '/course/list'
        return
      }

      this.ruleForm = course_info
      this.ruleForm.hide_title = this.ruleForm.hide_title === 1 ? true : false
      this.courseDesc = course_info.info
      this.editor = initEditor(course_info.info)
      console.log(course_info.cover, course_info, '封面')
      this.cover = course_info.cover ? course_info.cover.slice(52) : course_info.cover
    },


    async submit () {
      const html = this.editor.getHTML()
      console.log(1111, this.cover, this.ruleForm, html)
      if (!this.cover  || !this.ruleForm.age ||
      !html || !this.ruleForm.title || !this.ruleForm.difficulty || !this.ruleForm.sid) {
        this.$message({
          message: '需要填写必填的信息',
          type: 'warning'
        })
        return
      }

      if (this.id) {
        const json = await api.editCourse(this.ruleForm.sid, this.id, this.ruleForm.title, this.ruleForm.age, this.ruleForm.difficulty, this.cover, html, this.ruleForm.hide_title)
        if (json.ret !== 1000) {
          this.$message({
            message: '出错了，请重试',
            type: 'error'
          })
          return
        }

        // console.log(json)
        location.href = '/course/list'
        return
      }

      const json = await api.addCourse(this.ruleForm.sid, this.ruleForm.title, this.ruleForm.age, this.ruleForm.difficulty, this.cover, html, this.ruleForm.hide_title)
      if (json.ret !== 1000) {
        this.$message({
          message: '出错了，请重试',
          type: 'error'
        })
        return
      }

      location.href = '/course/list'
    }
  },

  created () {
    const height = document.documentElement.scrollHeight
    window.onscroll = () => {
      if (document.documentElement.scrollTop > height / 2) {
        this.scrollStatus = 2
      } else if (document.documentElement.scrollTop < height / 2) {
        this.scrollStatus = 0
      } else {
        this.scrollStatus = 1
      }
    }

    //  修改课程
    const id = this.$route.query.id
    if (id) {
      this.id = id
      this.getCourseInfo()
    } else {
      this.editor = initEditor('')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.add-course-container {
  background: #f8f8f8;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  box-sizing: border-box;
}

.course-editor-box {
  background: #fff;
  width: 760px;
  box-sizing: border-box;
  /* border: 1px solid red; */
  position: relative;
  padding-top: 100px;
  padding-bottom: 120px;
}

.name .el-input__inner {
  border: none;
}

.name .el-input__inner::placeholder {
  font-size: 20px;
  color: #adb5bd;
}

.name {
  padding: 0px 16px;
  /* border: 1px solid green; */
  box-sizing: border-box;
}




/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

/* Basic editor styles */
.ProseMirror>*+* {
  margin-top: 0.75em;
}

.ProseMirror {
  padding: 0px 30px;
  outline: none;
  min-height: 90vh;

  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 17px;
}

.ProseMirror:hover {
  outline: none;
}

.ProseMirror ul,
.ProseMirror ol {
  padding: 0 1rem;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}

.ProseMirror code {
  background-color: rgba(97, 97, 97, 0.1);
  color: #616161;
}

.ProseMirror pre {
  background: #0D0D0D;
  color: #FFF;
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.ProseMirror img {
  max-width: 100%;
  height: auto;
}

.ProseMirror-selectednode {
  outline: 3px solid #68CEF8;
}

.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}

.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
}

.my-custom-is-empty-class {
  color: red;
}

.editor-is-active {
  background: #409EFF;
  color: #fff;
  border: 1px solid #409EFF;
}


  .img-label {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 5px;
    color: #333;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
  }

  input {
      display: block;
    }
  #upload-file {
    display: none;
  }


.editor-toolbar {
  position: fixed;
  width: 760px;
  box-sizing: border-box;
  background: #f8f8f8;
  top: 0;
  z-index: 4;
  padding: 10px;
}

.editor-footer-box {
  width: 760px;
  position: fixed;
  box-sizing: border-box;
  z-index: 4;
  bottom: 0;
  border-top: 1px solid rgba(13, 13, 13, 0.1);
  padding: 20px;
  display: flex;
  justify-content: center;
  background: #fff;
}

.editor-meta-box {
  border-top: 1px solid rgba(13, 13, 13, 0.1);
  width: 700px;
  margin: auto;
  padding: 20px 0;
}

.editor-meta-upload-img-box {
  display: flex;
  padding: 10px 0;
}

.editor-meta-upload-img-r {
  margin-left: 20px;
  position: relative;
}

/* .editor-meta-upload-img-btn {
  border: 1px solid green;
  cursor: pointer;
  width: 375px;
  height: calc(375px / 1.83);
} */

.upload-cover {
  font-size: 16px;
  border-radius: 5px;
  color: #333;
  border: 1px dashed #ccc;
  background-color: #fff;
  cursor: pointer;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: calc(200px / 1.83);
}

.upload-cover-r {
  position: absolute;
  left: 70px;
  top: calc(70px / 1.83);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 16px !important;
  width: 60px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-input {
  display: none
}

#upload-file {
  display: block;
}


.vue-cropper {
  z-index: 5;
  width: 600px;
  height: 350px;
  margin-bottom: 20px;
  border: 1px solid red;
  box-sizing: border-box;
}

.cropper-box {
  /* width: 300px; */
  height: 350px;
  border: 1px solid green;
  width: 100%;
  box-sizing: border-box;
}

.cropper-dialog {
  display: flex;
  border: 1px solid blue;
  box-sizing: border-box;
}

.cropper-submit-box {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.add-course-container  .el-dialog__body{
  padding: 0 20px;
  box-sizing: border-box;
}

.submit-box {
  /* border: 1px solid green; */
  text-align: center;
  margin-top: 60px;
}

.cover-preview {
  width: 200px;
  height: calc(200px / 1.83);
  box-sizing: border-box;
  border-radius: 5px;
}

.editor-meta-input .el-input__inner {
  width: 200px;
  box-sizing: border-box;
}

.editor-meta-select {
  width: 200px;
}

.scoll-box {
  position: fixed;
  right: calc((100vw - 760px) / 2 - 130px);
  bottom: 80px;
  box-sizing: border-box;
  box-shadow: 0 1px 4px 0 rgb(227 226 226 / 60%);
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100px; */
  padding: 0 15px 0 13px;
  font-size: 14px;
  white-space: nowrap;
  line-height: 34px;
  border-radius: 18px;
  background-color: #fff;
  color: #9a9a9a;
  cursor: pointer;
}

.scoll-box-item {
  display: flex;
  align-items: center;
}

.scoll-icon {
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  margin-right: 8px;
}
</style>
