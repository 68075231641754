const api = require('../api/index')

function format (date, fmt) {
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
    S: date.getMilliseconds()
  }
  let fmtDate = fmt
  if (/(y+)/.test(fmtDate)) {
    fmtDate = fmtDate.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length))
  }
  Object.keys(o).forEach(k => {
    if (new RegExp(`(${k})`).test(fmtDate)) {
      fmtDate = fmtDate.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : ((`00${o[k]}`).substr((`${o[k]}`).length)))
    }
  })
  return fmtDate
}

function getTime (time) {
  const num = new Date(time).valueOf()
  const now = new Date().valueOf() + 1000 * 90
  const minus = now - num
  if (minus >= 1000 * 60 * 60 * 24 * 6) {
    return format(new Date(time), 'yyyy/MM/dd hh:mm')
  } else if (minus >= 1000 * 60 * 60 * 24) {
    return Math.floor(minus / (1000 * 60 * 60 * 24)) + ' 天前'
  } else if (minus > 1000 * 60 * 60) {
    return Math.floor(minus / (1000 * 60 * 60)) + ' 时前'
  } else if (minus > 1000 * 60) {
    const s = Math.floor(minus / (1000 * 60))
    console.log(minus / (1000 * 60))
    return s + ' 分前'
  } else {
    const s = Math.floor(minus / 1000) > 0 ? Math.floor(minus / 1000) : 1
    console.log(minus / (1000 * 60))
    return s + ' 秒前'
  }
}

async function filter (content) {
  if (!content) {
    return true
  }

  return new Promise(async (resolve, reject) => {

    try {
      const json = await api.checkContent(content)
      // console.log('内容安全检测接口', json)
      const result = json.data === 'pass' ? true : false
      resolve(result)
    } catch (e) {
      reject('error')
    }

    // wx.cloud.callFunction({
    //   name: 'filter',
    //   data: {
    //     content
    //   }
    // })
    //   .then(res => {
    //     resolve(res.result)
    //   }, err => {
    //     console.log(err)
    //     reject(err)
    //   })
  })
}

module.exports = {
  getTime,
  filter,
  format
}
